import * as API from ".";
import * as Types from "../main";
import { Bill as ApartmentBill } from "./Staff/Apartment";

export const subRoute = "/customer";

export type GuestUser = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email?: string;
  lineId?: string;
};

export namespace Booking {
  export const subPath = "/booking";

  export type CustomerApartmentBooking = Types.Bill.ApartmentBill;
  export type CustomerCoWorkingBooking = Types.Bill.CoWorkingBill;
  export type BookingResponse = {
    apartment: CustomerApartmentBooking;
    coWorking: CustomerCoWorkingBooking;
  };
  export type BookingRoomResponse = CustomerApartmentBooking[] | CustomerCoWorkingBooking[];
  export type MyBookingRoomResponse = CustomerApartmentBooking | CustomerCoWorkingBooking;
  /**
   * Get all bookings (daily room rental + cw room)
   *
   * - path: /customer/booking/get-all
   * - method: "GET"
   * @ for registered customer.
   */
  export namespace GetAll {
    export const subPath = "/get-all";
    /** querystring */
    export type Request = API.Pagination;
    export type Response = API.ResponsePayload<BookingResponse>;
  }

  /**
   * Get all bookings by department (daily room rental + cw room)
   *
   * - path: /customer/booking/get-all
   * - method: "GET"
   * @ for registered customer.
   */
  export namespace GetAllBooking {
    export const subPath = "/get-all-booking";
    /** querystring */
    export type Request = API.Pagination & { department: Types.Department.Def };
    export type Response = API.ResponsePayload<BookingRoomResponse | null>;
  }

  /**
   * Upload a transaction image evidence.
   *
   * - path: /customer/booking/upload-transaction-img
   * - method: "POST"
   * - Content-Type: multipart/form-data
   * - name: "image"
   * @ for registered customer.
   */
  export namespace UploadTransactionImg {
    /** body */
    export type Request = {
      billId: any;
      image: any;
      paidDate: any;
    };
    export const subPath = "/upload-transaction-img";
    export type Response = API.ResponsePayload<{
      /**/
    }>;
  }

  /**
   * Get booking by bill No.
   *
   * - path: /customer/booking/get-my-booking
   * - method: "GET"
   * @ for all customer type.
   */
  export namespace GetMyBooking {
    /** querystring */
    export type Request = {
      billNo: string;
    };
    export const subPath = "/get-my-booking";
    export type Response = API.ResponsePayload<{
      department: Types.Department.Def | null;
      bill: MyBookingRoomResponse | null;
      user: {
        firstName: string;
        lastName: string;
        phoneNumber: string;
      };
    }>;
  }

  /**
   * Cancel a unpaid booking.
   *
   * - path: /customer/booking/cancel-booking
   * - method: "DELETE"
   * @ for registered customer.
   */
  export namespace Cancel {
    /** body */
    export type Request = {
      bookingId: string;
    };
    export const subPath = "/cancel-booking";
    export type Response = API.ResponsePayload<{
      success: boolean;
    }>;
  }

  /**
   * Calculate room price.
   *
   * - path: /customer/booking/calculate-room-price
   * - method: GET
   * @ for apartment staff
   */
  export namespace CalculateRoomPrice {
    export const subPath = ApartmentBill.CalculateRoomPrice.subPath;
    /** querystring */
    export type Request = API.Staff.Apartment.Bill.CalculateRoomPrice.Request;
    export type Response = API.Staff.Apartment.Bill.CalculateRoomPrice.Response;
  }
}

export namespace Apartment {
  export const subPath = "/apartment";

  export namespace Bill {
    export const subPath = "/bill";
    /**
     * Get month bill of own rental rooms.
     *
     * - path: /customer/apartment/bill/get-month-bill
     * - method: "GET"
     * @ for registered apartment monthly-rental customer.
     */
    export namespace GetMonthBill {
      export const subPath = "/get-month-bill";
      /** querystring */
      export type Request = {
        //
      };
      export type MonthBillResponse = Pick<
        Types.Bill.Type<"apartment">,
        | "id"
        | "rentFee"
        | "purchasedProducts"
        | "units"
        | "expiryDate"
        | "subTotal"
        | "total"
        | "status"
      > & {
        booking: {
          room: {
            roomId: string;
            roomNo: string;
            startDate: number;
            endDate: number;
          };
        };
      };

      export type Response = API.ResponsePayload<MonthBillResponse[]>;
    }

    /**
     * Get own expense.
     *
     * - path: /customer/apartment/bill/get-expense-histories
     * - method: "GET"
     * @ for registered apartment monthly-rental customer.
     */
    export namespace GetExpenseHistories {
      export const subPath = "/get-expense-histories";
      /** querystring */
      export type Request = {
        roomId: string;
        type: "room-rent" | "water" | "electric" | "goods";
      };

      export type Expense = {
        monthYear: Types.MonthYear;
        amount: number;
        paidDate: number | null;
      };

      export type Response = API.ResponsePayload<Expense[]>;
    }
  }

  export namespace Discount {
    export const subPath = "/discount";
    /**
     * Try apply an discount to booking.
     *
     * - path: /customer/apartment/try-apply-discount
     * - method: "POST"
     * @ for registered customer.
     */
    export namespace TryApply {
      export const subPath = "/try-apply";
      /** body */
      export type Request = API.Staff.Apartment.Discount.TryApply.Request;
      export type Response = API.Staff.Apartment.Discount.TryApply.Response;
    }
  }

  export namespace Room {
    export const subPath = "/room";
    /**
     * Get a reservable apartment rooms (daily).
     *
     * - path: /customer/apartment/room/get-reservable
     * - method: "GET"
     * @ for registered customer.
     */
    export namespace GetReservableRoom {
      export const subPath = "/get-reservable";
      /** querystring */
      export type Request = {
        startDate: number;
        endDate: number;
      };
      export type Response = API.ResponsePayload<
        Omit<Types.Apartment.Room.Type, "renter" | "rentType" | "available" | "monthlyPrice">[]
      >;
    }

    /**
     * Book a daily apartment rooms.
     *
     * - path: /customer/apartment/room/book
     * - method: "POST"
     * @ for registered customer.
     */
    export namespace Book {
      export const subPath = "/book";
      /** body */
      export type Request = {
        roomId: string;
        startDate: number;
        endDate: number;
        code?: string;
      };
      export type Response = API.ResponsePayload<{
        billId: string;
      }>;
    }

    /**
     * Get a available apartment rooms (daily).
     *
     * - path: /customer/apartment/room/get-available
     * - method: "GET"
     * @ for all customer type (both member and guest).
     */
    export namespace GetAvailableRoom {
      export const subPath = "/get-available";
      /** querystring */
      export type Request = {
        startDate: number;
        endDate: number;
      };
      export type Response = API.ResponsePayload<
        Pick<Types.Apartment.Room.Type, "roomType" | "dailyPrice" | "weekendPrice">[]
      >;
    }

    /**
     * Member book apartment room (daily).
     *
     * - path: /customer/apartment/room/member-book
     * - method: "POST"
     * @ for member type.
     */
    export namespace MemberBook {
      export const subPath = "/member-book";
      /** body */
      export type Request = {
        roomType: Types.Apartment.Room.RoomType.Def;
        startDate: number;
        endDate: number;
        code?: string;
      };
      export type Response = API.ResponsePayload<{
        billId: string;
        billNo: string;
      }>;
    }

    /**
     * Guest book apartment room (daily).
     *
     * - path: /customer/apartment/room/guest-book
     * - method: "POST"
     * @ for guest type.
     */
    export namespace GuestBook {
      export const subPath = "/guest-book";
      /** body */
      export type Request = {
        roomType: Types.Apartment.Room.RoomType.Def;
        startDate: number;
        endDate: number;
        guest: GuestUser;
        code?: string;
      };
      export type Response = API.ResponsePayload<{
        billId: string;
        billNo: string;
      }>;
    }

    /**
     * New book apartment room (daily).
     *
     * - path: /customer/apartment/room/daily-book
     * - method: "POST"
     * @ for all customer type (both member and guest).
     */
    export namespace DailyBook {
      export const subPath = "/daily-book";
      /** body */
      export type Request = {
        roomType: Types.Apartment.Room.RoomType.Def;
        startDate: number;
        endDate: number;
        code?: string;
        /** for the case of guest user booking room */
        isGuestUser: boolean;
        guest?: GuestUser;
      };
      export type Response = API.ResponsePayload<{
        billId: string;
        billNo: string;
      }>;
    }
  }

  export namespace IssueReport {
    export const subPath = "/issue-report";
    /**
     * Get own issue reports.
     *
     * - path: /customer/apartment/issue-report/get-all
     * - method: "GET"
     * @ for registered apartment monthly-rental customer.
     */
    export namespace GetAll {
      export const subPath = "/get-all";
      /** querystring */
      export type Request = API.Pagination & {
        roomId: string;
      };
      export type Response = API.ResponsePayload<
        Omit<Types.Apartment.IssueReport, "userId" | "roomId">[]
      >;
    }

    /**
     * Create an issue report.
     *
     * - path: /customer/apartment/issue-report/create
     * - method: "POST"
     * @ for registered apartment monthly-rental customer.
     */
    export namespace Create {
      export const subPath = "/create";
      /** body */
      export type Request = Pick<Types.Apartment.IssueReport, "title" | "description" | "roomId">;
      export type Response = API.ResponsePayload<Request>;
    }

    /**
     * Confirm an issue report.
     *
     * - path: /customer/apartment/issue-report/confirm-resolved
     * - method: "PUT"
     * @ for registered apartment monthly-rental customer.
     */
    export namespace ConfirmResolved {
      export const subPath = "/confirm-resolved";
      /** body */
      export type Request = Pick<Types.Apartment.IssueReport, "solvedDate" | "id">;
      export type Response = API.ResponsePayload<Request>;
    }
  }
}

export namespace CoWorking {
  export const subPath = "/co-working";

  /**
   * Get all co-working rooms.
   *
   * - path: /customer/co-working/get-all-room
   * - method: "GET"
   * @ for registered customer.
   */
  export namespace GetAllRoom {
    export const subPath = "/get-all-room";
    export type Room = {
      id: string;
      name: string;
      details: string[];
      price: {
        time: number;
        price: number;
      }[];
      picture: string;
    };
    /** querystring */
    export type Request = {
      /** */
    };
    export type Response = API.ResponsePayload<Room[]>;
  }
  /**
   * Get reserved time slots of the room.
   *
   * - path: /customer/co-working/get-reserved-timeslot
   * - method: "GET"
   * @ for registered customer.
   */
  export namespace GetReservedTimeSlot {
    export const subPath = "/get-reserved-timeslot";
    /** querystring */
    export type Request = {
      roomId: string;
      date: number;
    };
    export type Response = API.ResponsePayload<Types.TimeSlot[]>;
  }

  /**
   * Book a daily co-working rooms.
   *
   * - path: /customer/co-working/book
   * - method: "POST"
   * @ for registered customer.
   */
  export namespace Book {
    export const subPath = "/book";
    /** body */
    export type Request = {
      roomId: string;
      bookDate: number;
      timeSlot: Types.TimeSlot[];
      code?: string;
      /** for the case of guest user booking room */
      isGuestUser: boolean;
      guest?: GuestUser;
    };
    export type Response = API.ResponsePayload<
      { billId?: string; billNo?: string },
      "book your co-working space room successfully"
    >;
  }

  /**
   * Book a daily co-working rooms (member type).
   *
   * - path: /customer/co-working/member-book
   * - method: "POST"
   * @ for member.
   */
  export namespace MemberBook {
    export const subPath = "/member-book";
    /** body */
    export type Request = {
      roomId: string;
      bookDate: number;
      timeSlot: Types.TimeSlot[];
      code?: string;
    };
    export type Response = API.ResponsePayload<
      {
        billId: string;
        billNo: string;
      },
      "book your co-working space room successfully"
    >;
  }

  /**
   * Book a daily co-working rooms (guest type).
   *
   * - path: /customer/co-working/guest-book
   * - method: "POST"
   * @ for guest.
   */
  export namespace GuestBook {
    export const subPath = "/guest-book";
    /** body */
    export type Request = {
      roomId: string;
      bookDate: number;
      timeSlot: Types.TimeSlot[];
      guest: GuestUser;
      code?: string;
    };
    export type Response = API.ResponsePayload<
      {
        billId: string;
        billNo: string;
      },
      "book your co-working space room successfully"
    >;
  }

  /**
   * Try apply an discount to booking.
   *
   * - path: /customer/co-working/try-apply-discount
   * - method: "POST"
   * @ for registered customer.
   */
  export namespace TryApplyDiscount {
    export const subPath = "/try-apply-discount";
    /** body */
    export type Request = API.Staff.Apartment.Discount.TryApply.Request;
    export type Response = API.Staff.Apartment.Discount.TryApply.Response;
  }
}
