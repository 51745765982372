import * as API from "../../api";
import * as Types from "../../main";

export const subPath = "/manager";

export namespace Booking {
  export const subPath = "/booking";

  /**
   * Get all bookings (apartment & co-working)
   *
   * - path: /manager/booking/get-all
   * - method: "GET"
   * @ for manager
   */
  export namespace GetAll {
    export const subPath = "/get-all";
    /** querystring */
    export type Request = Types.MonthYear;

    export type Response = API.ResponsePayload<{
      apartmentBookings: API.Staff.Apartment.Booking.GetCalendarView.Response["data"];
      coWorkingBookings: API.Staff.CoWorking.Booking.GetAll.Response["data"];
    }>;
  }
}

export namespace CoWorking {
  export const subPath = "/co-working";
  /**
   * Get co-working rooms
   *
   * - path: /manager/co-working/get-rooms
   * - method: "GET"
   * @ for manager
   */
  export namespace GetRooms {
    export const subPath = "/get-rooms";
    /** querystring */
    export type Request = {
      //
    };

    export type Response = API.ResponsePayload<API.Staff.CoWorking.Room.GetAll.Response["data"]>;
  }
}

export namespace Report {
  export const subPath = "/report";

  export type BaseReportRequest = {
    dateIn: number;
    dateOut: number;
  };

  export type TotalPrice = {
    subTotal: number;
    totalDiscount: number;
    total: number;
  };

  /**
   * - amount = calculated.
   */
  export type Discount = Pick<Types.Discount.Type, "code" | "amount"> | null;

  /**
   * Get a co-working summary report
   *
   * - path: /manager/report/get-co-working
   * - method: "GET"
   * @ for manager
   */
  export namespace CoWorking {
    /** querystring */
    export type Request = BaseReportRequest & {
      selectedRooms: string[];
      goods: boolean;
    };

    export type SoldSummaryItem = {
      timestamp: number;
      roomName: string | null;
      billType: Types.Bill.BillType.Def;
      amount: number | null;
      price: number;
      total: number;
      discount: Discount;
    };

    export type ReservationSummaryItem = {
      timestamp: number;
      roomName: string;
      timeSlot: Types.TimeSlot[];
      discount: Discount;
      billStatus: Types.Bill.Status.Def;
      total: number;
    };

    export type GoodSummaryItem = {
      timestamp: number;
      name: string;
      discount: Discount;
      price: number;
      amount: number;
      total: number;
    };

    export type AllSummaryItem = {
      amount: number;
      paidCount: number | null;
      overdueCount: number | null;
      totalPaid: number | null;
      totalOverdue: number | null;
      expected: number;
    };

    export type TopRoomReservation = {
      timeSlot: [number, number];
      /** amount of top room reservation of that time slot */
      amount: number;
      roomName: number;
      /** income of top room reservation of that time slot */
      income: number;
    };

    type ReservationSummary = {
      timeSlot: [number, number];
      amount: number;
      income: number;
      discount: number;
      total: number;
    };

    export type SumReservationOfRoom = {
      roomName: string;
      items: ReservationSummary[];
    };

    export type Response = API.ResponsePayload<{
      /** all sold items (completed transactions) sorted by date */
      sold: {
        items: SoldSummaryItem[];
      } & TotalPrice;
      /** all room reservation items (all transactions) sorted by date */
      reservations: {
        items: ReservationSummaryItem[];
      } & TotalPrice;
      /** all good items (completed transactions) sorted by date */
      goods: {
        items: ReservationSummaryItem[];
      } & TotalPrice;
      /** all kinds of item summary */
      summary: {
        reservation: AllSummaryItem;
        goods: AllSummaryItem;
        discount: AllSummaryItem;
      } & TotalPrice;
      /** top room reservation summary */
      topRoomReservation: TopRoomReservation[];
      /** sum reservation separated by room summary */
      sumReservationOfRoom: SumReservationOfRoom[];
    }>;
  }

  /**
   * Get a co-working summary report
   *
   * - path: /manager/report/get-apartment
   * - method: "GET"
   *  @ for manager
   */
  export namespace Apartment {
    /** querystring */
    export type Request = BaseReportRequest & {
      water: boolean;
      roomRental: boolean;
      electric: boolean;
      goods: boolean;
    };

    export type SeparatedRoomExpense = {
      roomNo: string;
      rentPrice: number;
      electricPrice: number;
      waterPrice: number;
      discount: Discount;
      rentPaid: boolean;
      total: number;
    };

    export type RoomRental = {
      roomNo: string;
      price: number;
      discount: Discount;
      overdue: boolean;
    };

    export type Electric = {
      roomNo: string;
      amount: number;
      price: number;
      discount: Discount;
      overdue: boolean;
      total: number;
    };

    export type Water = Electric;

    export type Goods = {
      name: string;
      amount: number;
      price: number;
      total: number;
    };

    export type Summary = {
      amount: number;
      // totalCount: number | null;
      paidCount: number | null;
      overdueCount: number | null;
      paid: number;
      overdue: number;
      expected: number;
    };

    export type Response = API.ResponsePayload<
      Pick<CoWorking.Response["data"], "sold"> & {
        separatedRoomExpense: {
          items: SeparatedRoomExpense;
        } & TotalPrice;
        expense: {
          roomRental: {
            items: RoomRental[];
          } & TotalPrice;
          electric: {
            items: Electric[];
          } & TotalPrice;
          water: {
            items: Water[];
          } & TotalPrice;
          goods: {
            items: Goods[];
          } & TotalPrice;
        };
        summary: {
          rental: Summary;
          water: Summary;
          electric: Summary;
          goods: Summary;
          discount: Summary;
        } & TotalPrice;
      }
    >;
  }

  /**
   * __Hold on
   *
   * Get a co-working summary report
   *
   * - path: /manager/report/get-all-staff
   * - method: "GET"
   *  @ for manager
   */
  export namespace Staff {
    /**/
  }
}

export namespace Notification {
  export const subPath = "/notification";

  /**
   *
   * Get all apartment daily booking notifications.
   *
   * - path: /manager/notification/get-all
   * - method: GET
   * @ for manager
   */
  export namespace GetAll {
    export const subPath = "/get-all";
    /** querystring */
    export type Request = {
      /** */
    };
    export type Response = API.ResponsePayload<
      Types.Notification.Type[],
      "get all notifications successfully"
    >;
  }

  /**
   *
   * Set notification is read.
   *
   * - path: /staff/manager/notification/set-is-read
   * - method: PUT
   * @ for manager
   */
  export namespace SetIsRead {
    export const subPath = "/set-is-read";
    /** body */
    export type Request = {
      notiId: string;
      isRead: boolean;
    };
    export type Response = API.ResponsePayload<Request, "set status of notification successfully">;
  }
}

export namespace User {
  export const subPath = "/user";

  /**
   * Register a new staff
   *
   * - path: /manager/user/register-staff
   * - method: "POST"
   * @ for manager
   */
  export namespace RegisterStaff {
    export const subPath = "/register-staff";
    /** body */
    export type Request = Omit<
      Types.User.Type,
      "id" | "memberExpiryDate" | "isApartmentMember" | "active" | "role" | "userNo"
    >;
    export type Response = API.ResponsePayload<
      null,
      | "registered a new customer user successfully"
      | {
          error: API.User.Register.ErrorMessage;
        }
    >;
  }

  /**
   * Get all staffs
   *
   * - path: /manager/user/get-all-staffs
   * - method: "GET"
   *  @ for manager
   */
  export namespace GetAllStaff {
    export const subPath = "/get-all-staffs";
    /** querystring */
    export type Request = API.Pagination & {
      search?: string;
      active?: boolean;
    };
    export type Response = API.ResponsePayload<
      Omit<Types.User.Type, "memberExpiryDate" | "isApartmentMember">[]
    >;
  }

  /**
   * Edit a staff
   *
   * - path: /manager/user/edit-staff
   * - method: "POST"
   *  @ for manager
   */
  export namespace EditStaff {
    export type DefaultData = Pick<Types.User.Type, "id"> &
      Partial<
        Pick<
          Types.User.Type,
          | "firstName"
          | "lastName"
          // | "role"
          | "phoneNumber"
          | "email"
          | "lineId"
          | "bankAccountNo"
          | "bankAccountName"
          | "bank"
          | "position"
          | "salary"
          | "startWorkingDate"
          | "endWorkingDate"
        >
      >;
    //
    export const subPath = "/edit-staff";
    /** body */
    export type Request = DefaultData;
    export type Response = API.ResponsePayload<null, "edit staff info successfully">;
  }

  /**
   * Remove a staff
   *
   * - path: /manager/user/remove-staff
   * - method: "DELETE"
   * @ for manager
   */
  export namespace RemoveStaff {
    //
    export const subPath = "/remove-staff";
    /** body */
    export type Request = {
      id: string;
    };
    export type Response = API.ResponsePayload<null, "remove staff successfully">;
  }
}
