import { Types } from "shared-lib/src/types";
import { Month } from "shared-lib/src/types/main";

export const convertBookingStatus = (booking: Types.BookingStatus.Def) => {
  switch (booking) {
    case "checked-out":
      return "checked-out";
    case "canceled":
      return "ยกเลิก";
    case "waiting":
      return "กำลังรอการยืนยัน";
    case "confirmed":
      return "จองแล้ว";
    case "checked-in":
      return "checked-in";
  }
};

export const convertDepartmentType = (department: Types.Department.Def) => {
  switch (department) {
    case "apartment":
      return "อพาร์ทเมนต์";
    case "co-working":
      return "Co-Working";
  }
};

export const convertRentType = (rentType: Types.Apartment.Room.RentType.Def) => {
  switch (rentType) {
    case "daily":
      return "ห้องพักรายวัน";
    case "monthly":
      return "ห้องพักรายเดือน";
  }
};

export const convertPaymentMethod = (payment: Types.Bill.PaymentMethod.Def) => {
  switch (payment) {
    case "cash":
      return "เงินสด";
    case "qr":
      return "QR CODE";
  }
};

export const convertBillStatus = (bill: Types.Bill.Status.Def) => {
  switch (bill) {
    case "unpaid":
      return "ยังไม่ชำระ";
    case "paid":
      return "ชำระแล้ว";
    case "expired":
      return "หมดอายุ";
  }
};

export const getMonthValue = (month: number) => {
  let currentMonthValue: Month = 1;
  switch (month) {
    case 1:
      currentMonthValue = 1;
      break;
    case 2:
      currentMonthValue = 2;
      break;
    case 3:
      currentMonthValue = 3;
      break;
    case 4:
      currentMonthValue = 4;
      break;
    case 5:
      currentMonthValue = 5;
      break;
    case 6:
      currentMonthValue = 6;
      break;
    case 7:
      currentMonthValue = 7;
      break;
    case 8:
      currentMonthValue = 8;
      break;
    case 9:
      currentMonthValue = 9;
      break;
    case 10:
      currentMonthValue = 10;
      break;
    case -1:
      currentMonthValue = 11;
      break;

    default:
      currentMonthValue = 0;
      break;
  }
  return currentMonthValue;
};

export const converApartmentDailyRoomType = (type: Types.Apartment.Room.RoomType.Def) => {
  switch (type) {
    case "single": {
      return "ห้องเตียงเดี่ยว";
    }
    case "twin": {
      return "ห้องเตียงคู่";
    }
    case "double": {
      return "ห้องเตียง 5 ฟุต";
    }
  }
};

export const convertCoWorkingRoomType = (type: Types.CoWorking.RoomType.Def) => {
  switch (type) {
    case "meeting":
      return "ห้องประชุม";
    case "music":
      return "ห้องซ้อมดนตรี";
    case "dancing":
      return "ห้องซ้อมเต้น";
    case "central":
      return "ห้องส่วนกลาง";
    default:
      return "";
  }
};
