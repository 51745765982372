import { Room, RoomPrices } from "../types/room";

export const initialRoomPrices: RoomPrices = {
  "1hour": 1,
  "3hour": null,
  "6hour": null,
  allDay: null,
};

export const initialRoomData: Room = {
  id: "",
  type: "meeting",
  name: "",
  capacity: 1,
  prices: initialRoomPrices,
  picture: "",
  detail: [],
};
