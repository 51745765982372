import react from "react";

export type UserContextType = {
  auth: boolean;
  setAuth: (value: boolean) => void;
};

const UserContext = react.createContext<UserContextType | null>(null);

export default UserContext;
