import * as API from "../../api";
import * as Apartment from "./Apartment";
import * as CoWorking from "./CoWorking";
import * as Manager from "./Manager";

export const subRoute = "/staff";
// import * as Type from "../../main";

export namespace User {
  export const subPath = "/user";
  /**
   * Get all customer users
   *
   * - path: /staff/user/get-all
   * - method: "GET"
   * @ only for co-working, apartment staffs
   */
  export namespace GetAll {
    export const subPath = "/get-all";
    /** querystring */
    export type Request = API.Pagination & {
      isMonthlyApartmentMember?: boolean;
    };
    export type Response = API.ResponsePayload<API.User.CustomType.Info[]>;
  }

  /**
   * Get a customer user by id
   *
   * - path: /staff/user/get-by-id
   * - method: "GET"
   * @ only for co-working, apartment staffs
   */
  export namespace GetById {
    export const subPath = "/get-by-id";
    /** querystring */
    export type Request = {
      id: string;
    };
    export type Response = API.ResponsePayload<API.User.CustomType.Info>;
  }

  /**
   * Get a customer user by searching from
   * name (combined with firstName and lastName)
   * or phoneNumber
   *
   * - path: /staff/user/search
   * - method: "GET"
   * @ only for co-working, apartment staffs
   */
  export namespace Search {
    export const subPath = "/search";
    /** querystring */
    export type Request = API.Pagination & {
      search: string;
    };
    export type Response = API.ResponsePayload<API.User.CustomType.Info[]>;
  }

  /**
   * Remove customer user by id
   *
   * - path: /staff/user/remove-by-id
   * - method: "DELETE"
   * @ for staff
   */
  export namespace RemoveUserById {
    export const subPath = "/remove-by-id";
    /** body */
    export type Request = {
      id: string;
    };
    export type Response = API.ResponsePayload<{
      /**/
    }>;
  }

  /**
   * Edit customer user info
   *
   * - path: /staff/user/edit
   * - method: "PUT"
   * @ for admin user or system
   */
  export namespace Edit {
    export const subPath = "/edit";
    /** body */
    export type Request = API.User.Edit.DefaultData & {
      id: string;
    };
    export type Response = API.ResponsePayload<{
      /**/
    }>;
  }
}

// export namespace Admin {
//   export const subPath = "/admin";

//   export namespace User {
//     /**
//      * Register a new manager
//      *
//      * - path: /admin/user/register-staff
//      * - method: "POST"
//      * @ for admin user or system
//      */
//     export namespace RegisterManager {
//       export const subPath = "/register-staff";
//       /** body */
//       export type Request = { secret: string } & Omit<
//         Type.User.Type,
//         "id" | "memberExpiryDate" | "isApartmentMember"
//       >;
//       export type Response = API.ResponsePayload<
//         {
//           /**/
//         },
//         {
//           error: API.User.Register.ErrorMessage;
//         }
//       >;
//     }
//   }
// }

export { Apartment, CoWorking, Manager };
