import { useState } from "react";
import { Flex } from "./Container";
import { FormControl, Button, InputGroup } from "react-bootstrap";
import { Checkbox } from "./Checkbox";

const Input = (props: {
  disabled?: boolean;
  placeholder?: string;
  value: string;
  type?: "text" | "password" | "number";
  readOnly?: boolean;
  textAlign?: "start" | "center" | "end";
  setValue: (value: string) => void;
}) => {
  const { disabled, placeholder, value, setValue, type, readOnly, textAlign } = props;
  return (
    <Flex justifyContent="start" alignItems="start">
      <div className={"m-2 x-border-black rounded-3 flex-grow-1"}>
        <input
          type={type}
          {...{ value, disabled, placeholder, readOnly }}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          className={`x-input ${textAlign ? `text-${textAlign}` : ""}`}
        />
      </div>
    </Flex>
  );
};

const Search = (props: {
  placeholder?: string;
  searchValue: string;
  setSearchValue: (value: string) => void;
}) => {
  const { placeholder, searchValue, setSearchValue } = props;
  return (
    <InputGroup className="mb-3">
      <FormControl
        id="search-box"
        placeholder={placeholder}
        aria-label={placeholder}
        aria-describedby="basic-addon2"
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
      />
      <Button id="button-search">
        <i className="fas fa-search"></i>
      </Button>
    </InputGroup>
  );
};

const DayDropdownList = (props: {
  selected: string[];
  setSelected: (value: string[]) => void;
  data: string[];
  disabled?: boolean;
}) => {
  const { selected, setSelected, data, disabled } = props;
  const [isActive, setIsActive] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [checkedState, setCheckedState] = useState(new Array(data.length).fill(false));
  const firstDayList = data.slice(0, 4);
  const lastDayList = data.slice(4, 8);
  const dayList = ["จันทร์", "อังคาร", "พุธ", "พฤหัสบดี", "ศุกร์", "เสาร์", "อาทิตย์", "ทุกวัน"];

  const handleOnChange = (position: number) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item,
    );

    updatedCheckedState.forEach((item, index) => {
      if (item === true && !selected.includes(data[index])) {
        selected.push(data[index]);
        setSelected(selected);
      } else if (item === false && selected.includes(data[index])) {
        const newSelected = selected.filter((item) => data[index] !== item);
        setSelected(newSelected);
      }
    });
    setCheckedState(updatedCheckedState);
  };

  const showDropDownData = () => {
    if (selected !== null) {
      const posList: number[] = [];
      data.forEach((day, index) => {
        selected.forEach((select) => {
          if (select === day) {
            posList.push(index);
          }
        });
      });
      const updatedCheckedState = checkedState.map((check, index) =>
        posList.includes(index) ? !check : check,
      );
      setCheckedState(updatedCheckedState);
    }
  };

  return (
    <div className="dropdown">
      <div className="w-80">
        <button
          className={`btn dropdown-toggle ${disabled ? "disabled" : ""} w-100 ${
            showDropdown === false ? "" : "show"
          }`}
          id="dropdown-basic-button"
          type="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={showDropdown}
          onClick={() => {
            if (isActive === true) {
              setIsActive(false);
              setShowDropdown(false);
            } else {
              setIsActive(true);
              setShowDropdown(true);
            }
            showDropDownData();
          }}
        >
          {selected.sort((a, b) => {
            return dayList.indexOf(a) - dayList.indexOf(b);
          })}
        </button>
        {isActive === true ? (
          <div className="d-flex justify-content-center">
            <ul className={`dropdown-menu w-60 shadow-lg ${showDropdown === false ? "" : "show"}`}>
              <div className="row">
                <div className="col-6">
                  {firstDayList.map((data, index) => (
                    <li key={index}>
                      <div className="d-flex align-items-center justify-content-start p-1">
                        <Checkbox
                          label={data}
                          value={checkedState[index]}
                          onChange={() => {
                            handleOnChange(index);
                          }}
                        />
                      </div>
                    </li>
                  ))}
                </div>
                <div className="col-6">
                  {lastDayList.map((data, index) => (
                    <li key={index}>
                      <div className="d-flex align-items-center justify-content-start p-1">
                        <Checkbox
                          label={data}
                          value={checkedState[index + 4]}
                          onChange={() => {
                            handleOnChange(index + 4);
                          }}
                        />
                      </div>
                    </li>
                  ))}
                </div>
              </div>
            </ul>
          </div>
        ) : (
          <ul className={"dropdown-menu"} aria-labelledby="dropdown-basic-button">
            {data.map((data, index) => (
              <li key={index}>
                <button
                  className="dropdown-item"
                  onClick={() => {
                    setIsActive(false);
                    setShowDropdown(false);
                  }}
                >
                  {data}
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export { Input, Search, DayDropdownList };
