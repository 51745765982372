import _404img from "./404-error.png";
import { Button } from "./Button";

const NotFound = () => {
  return (
    <div className="pt-5">
      <div>
        <img src={_404img} height={128} alt="not-found" />
      </div>
      <div className="fs-4">ขออภัย! เราไม่พบหน้าดังกล่าว</div>
      <div>
        <Button to="/" colour="yellow">
          <span>กลับหน้าหลัก</span>
        </Button>
      </div>
    </div>
  );
};

export { NotFound };
