import white from "./logo-white.png";
import black from "./logo-black.png";

const Logo = (props: { color: "WHITE" | "BLACK"; height: number }) => {
  const { color, height } = props;
  if (color === "BLACK") {
    return <img height={height} src={black} alt="logo" />;
  }
  if (color === "WHITE") {
    return <img height={height} src={white} alt="logo" />;
  }
  return <></>;
};

export { Logo };
