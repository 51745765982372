import { BookingData } from "../types/book-room";
import { initialRoomData } from "./init-room";

export const initialBookingData: BookingData = {
  id: "",
  userId: "",
  status: "waiting",
  timeSlot: [],
  room: initialRoomData,
};
