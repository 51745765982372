import { useContext, useEffect } from "react";
import { useState } from "react";
import { Input } from "./Component/Input";
import { Button } from "./Component/Button";
import { AdminAPI } from "./Apartment/api/service";
import { delay } from "./helper";
import * as App from "./App";
import { useCookies } from "react-cookie";
import UserContext from "./Apartment/contexts/userContext";

const useLoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [formValidate, setFormValidate] = useState(false);
  const [invalidPermission, setInvalidPermission] = useState(false);
  const user = useContext(UserContext);
  const submitForm = async () => {
    if (formValidate) {
      setFormValidate(false);
      //setInvalidPermission(true);
      setFormValidate(true);
      userLogin(username, password);
    }
  };

  const userLogin = async (username: string, password: string) => {
    const LoginResponse = await AdminAPI.User.login({
      username: username,
      password: password,
      staff: true,
    });
    if (LoginResponse) {
      user?.setAuth(true);
    }
  };

  useEffect(() => {
    if (username.length >= 6 && password.length >= 6) {
      setFormValidate(true);
    } else {
      setFormValidate(false);
    }
  }, [username, password]);

  return {
    username,
    setUsername,
    password,
    setPassword,
    formValidate,
    submitForm,
    invalidPermission,
  };
};

const CONST = {
  TITLE: "XKHANEK PLACE",
  WARNING_FOR_STAFF_ONLY: "* สำหรับพนักงานเท่านั้น *",
  INVALID_PERMISSION: "บัญชีไม่มีสิทธิ์ใช้งาน !",
};

const InvalidMessage = (props: { invalid: boolean }) => {
  if (props.invalid) {
    return (
      <div className="pt-2">
        <span className="x-text-red">{CONST.INVALID_PERMISSION}</span>
      </div>
    );
  }
  return <></>;
};

const Login = () => {
  const {
    username,
    setUsername,
    password,
    setPassword,
    formValidate,
    submitForm,
    invalidPermission,
  } = useLoginForm();

  return (
    <div className="pt-64px">
      <div>
        <span className="bebas-font fs-1">{CONST.TITLE}</span>
      </div>
      <div className="pt-3 fs-4">กรุณาเข้าสู่ระบบ</div>
      <div className="pt-5 w-300px mx-auto">
        <form
          onSubmit={(event) => {
            event.preventDefault();
            submitForm();
          }}
        >
          <div>
            <Input placeholder="ชื่อผู้ใช้" value={username} setValue={setUsername} />
          </div>
          <div className="pt-1">
            <Input type="password" placeholder="รหัสผ่าน" value={password} setValue={setPassword} />
          </div>
          <InvalidMessage invalid={invalidPermission} />
          <div className="pt-4">
            <Button type="submit" disabled={!formValidate} colour="yellow">
              <div className="w-150px fw-bold">เข้าสู่ระบบ</div>
            </Button>
          </div>
        </form>
        <div className="pt-5">
          <span className="x-text-red fw-bold">{CONST.WARNING_FOR_STAFF_ONLY}</span>
        </div>
      </div>
    </div>
  );
};

export { Login };
