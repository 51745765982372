import http from "../../api/http";
import * as API from "shared-lib/src/types/api";

export const AdminAPI = {
  User: {
    async login(props: API.User.Login.Request) {
      const { username, password } = props;
      const req: API.User.Login.Request = {
        username,
        password,
        staff: true,
      };
      return http.post<API.User.Login.Response>(
        `${API.User.subPath}${API.User.Login.subPath}`,
        req,
        { withCredentials: true },
      );
    },
    async get_own() {
      return http.get<API.User.GetOwn.Response>(`${API.User.subPath}${API.User.GetOwn.subPath}`, {
        withCredentials: true,
      });
    },
    async log_out() {
      return http.post<API.User.Logout.Response>(
        `${API.User.subPath}${API.User.Logout.subPath}`,
        {},
        {
          withCredentials: true,
        },
      );
    },
    async refresh_token() {
      return http.post<API.User.Refresh.Response>(
        `${API.User.subPath}${API.User.Refresh.subPath}`,
        {
          withCredentials: true,
        },
      );
    },
  },
  Room: {
    async get_all(props: API.Staff.Apartment.Room.GetAll.Request) {
      const { limit, page, search, type } = props;
      const req: API.Staff.Apartment.Room.GetAll.Request = {
        limit,
        page,
        type,
        search,
      };
      return http.get<API.Staff.Apartment.Room.GetAll.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Room.subPath}${API.Staff.Apartment.Room.GetAll.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async get_one(props: API.Staff.Apartment.Room.GetById.Request) {
      const { id } = props;
      const req: API.Staff.Apartment.Room.GetById.Request = {
        id,
      };
      return http.get<API.Staff.Apartment.Room.GetById.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Room.subPath}${API.Staff.Apartment.Room.GetById.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async set_renter(props: API.Staff.Apartment.Room.SetRenter.Request) {
      const { userId, roomId, startDate, endDate, firstPaid, code, paymentMethod } = props;
      const req: API.Staff.Apartment.Room.SetRenter.Request = {
        userId,
        roomId,
        startDate,
        endDate,
        firstPaid,
        code,
        paymentMethod,
      };
      return http.put<API.Staff.Apartment.Room.SetRenter.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Room.subPath}${API.Staff.Apartment.Room.SetRenter.subPath}`,
        req,
        { withCredentials: true },
      );
    },
    async get_available_room(props: API.Staff.Apartment.Room.GetAvailableRoom.Request) {
      const { startDate, endDate, roomType } = props;
      const req: API.Staff.Apartment.Room.GetAvailableRoom.Request = {
        startDate,
        endDate,
        roomType,
      };
      return http.get<API.Staff.Apartment.Room.GetAvailableRoom.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Room.subPath}${API.Staff.Apartment.Room.GetAvailableRoom.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async remove_renter(props: API.Staff.Apartment.Room.RemoveRenter.Request) {
      const { roomId } = props;
      const req: API.Staff.Apartment.Room.RemoveRenter.Request = {
        roomId,
      };
      return http.put<API.Staff.Apartment.Room.RemoveRenter.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Room.subPath}${API.Staff.Apartment.Room.RemoveRenter.subPath}`,
        req,
        { withCredentials: true },
      );
    },
    async edit(props: API.Staff.Apartment.Room.Edit.Request) {
      const { roomId, rentType, monthlyPrice, dailyPrice, weekendPrice } = props;
      const req: API.Staff.Apartment.Room.Edit.Request = {
        roomId,
        rentType,
        monthlyPrice,
        dailyPrice,
        weekendPrice,
      };
      return http.put<API.Staff.Apartment.Room.Edit.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Room.subPath}${API.Staff.Apartment.Room.Edit.subPath}`,
        req,
        { withCredentials: true },
      );
    },
  },
  Discount: {
    async get_all(props: API.Staff.Apartment.Discount.GetAll.Request) {
      const { limit, page, search } = props;
      const req: API.Staff.Apartment.Discount.GetAll.Request = {
        limit,
        page,
        search,
      };
      return http.get<API.Staff.Apartment.Discount.GetAll.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Discount.subPath}${API.Staff.Apartment.Discount.GetAll.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async get_one(props: API.Staff.Apartment.Discount.GetOne.Request) {
      const { id } = props;
      const req: API.Staff.Apartment.Discount.GetOne.Request = {
        id,
      };
      return http.get<API.Staff.Apartment.Discount.GetOne.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Discount.subPath}${API.Staff.Apartment.Discount.GetOne.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async add(props: API.Staff.Apartment.Discount.Add.Request) {
      const { name, ...rest } = props;
      const req: API.Staff.Apartment.Discount.Add.Request = {
        name,
        ...rest,
      };
      return http.post<API.Staff.Apartment.Discount.Add.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Discount.subPath}${API.Staff.Apartment.Discount.Add.subPath}`,
        req,
        { withCredentials: true },
      );
    },
    async delete(props: API.Staff.Apartment.Discount.Remove.Request) {
      const { id } = props;
      const req: API.Staff.Apartment.Discount.Remove.Request = {
        id,
      };
      return http.delete<API.Staff.Apartment.Discount.Remove.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Discount.subPath}${API.Staff.Apartment.Discount.Remove.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async edit(props: API.Staff.Apartment.Discount.Edit.Request) {
      const { id, ...rest } = props;
      const req: API.Staff.Apartment.Product.Edit.Request = {
        id,
        ...rest,
      };
      return http.put<API.Staff.Apartment.Discount.Edit.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Discount.subPath}${API.Staff.Apartment.Discount.Edit.subPath}`,
        req,
        { withCredentials: true },
      );
    },
    async try_apply(props: API.Staff.Apartment.Discount.TryApply.Request) {
      const { code, items } = props;
      const req: API.Staff.Apartment.Discount.TryApply.Request = {
        code,
        items,
      };
      return http.post<API.Staff.Apartment.Discount.TryApply.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Discount.subPath}${API.Staff.Apartment.Discount.TryApply.subPath}`,
        req,
        { withCredentials: true },
      );
    },
  },
  Product: {
    async get_all(props: API.Staff.Apartment.Product.GetAll.Request) {
      const { limit, page, search } = props;
      const req: API.Staff.Apartment.Product.GetAll.Request = {
        limit,
        page,
        search,
      };
      return http.get<API.Staff.Apartment.Product.GetAll.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Product.subPath}${API.Staff.Apartment.Product.GetAll.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async add(props: API.Staff.Apartment.Product.AddOne.Request) {
      const { name, price } = props;
      const req: API.Staff.Apartment.Product.AddOne.Request = {
        name,
        price,
      };
      return http.post<API.Staff.Apartment.Discount.Add.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Product.subPath}${API.Staff.Apartment.Product.AddOne.subPath}`,
        req,
        { withCredentials: true },
      );
    },
    async delete(props: API.Staff.Apartment.Product.Remove.Request) {
      const { id } = props;
      const req: API.Staff.Apartment.Product.Remove.Request = {
        id,
      };
      return http.delete<API.Staff.Apartment.Product.Remove.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Product.subPath}${API.Staff.Apartment.Product.Remove.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async edit(props: API.Staff.Apartment.Product.Edit.Request) {
      const { id, ...rest } = props;
      const req: API.Staff.Apartment.Product.Edit.Request = {
        id,
        ...rest,
      };
      return http.put<API.Staff.Apartment.Product.Edit.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Product.subPath}${API.Staff.Apartment.Product.Edit.subPath}`,
        req,
        { withCredentials: true },
      );
    },
  },
  Notification: {
    async get_all(props: API.Staff.Apartment.Notification.GetAll.Request) {
      const req: API.Staff.Apartment.Notification.GetAll.Request = props;
      return http.get<API.Staff.Apartment.Notification.GetAll.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Notification.subPath}${API.Staff.Apartment.Notification.GetAll.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async set_read_status(props: API.Staff.Apartment.Notification.SetIsRead.Request) {
      const req: API.Staff.Apartment.Notification.SetIsRead.Request = props;
      return http.get<API.Staff.Apartment.Notification.GetAll.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Notification.subPath}${API.Staff.Apartment.Notification.SetIsRead.subPath}`,
        { params: req, withCredentials: true },
      );
    },
  },
  Member: {
    async get_all(props: API.Staff.Apartment.Member.GetAllMembers.Request) {
      const { limit, page, search, isApartmentMember, active } = props;
      const req: API.Staff.Apartment.Member.GetAllMembers.Request = {
        limit,
        page,
        search,
        isApartmentMember: isApartmentMember ?? undefined,
        active,
      };
      return http.get<API.Staff.Apartment.Member.GetAllMembers.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Member.subPath}${API.Staff.Apartment.Member.GetAllMembers.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async edit(props: API.Staff.Apartment.Member.EditMember.Request) {
      const { id, ...rest } = props;
      const req: API.Staff.Apartment.Member.EditMember.Request = {
        id,
        ...rest,
      };
      return http.put<API.Staff.Apartment.Member.EditMember.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Member.subPath}${API.Staff.Apartment.Member.EditMember.subPath}`,
        req,
        { withCredentials: true },
      );
    },
    async set_status(props: API.Staff.Apartment.Member.SetStatus.Request) {
      const { id, active } = props;
      const req: API.Staff.Apartment.Member.SetStatus.Request = {
        id,
        active,
      };
      return http.put<API.Staff.Apartment.Member.SetStatus.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Member.subPath}${API.Staff.Apartment.Member.SetStatus.subPath}`,
        req,
        { withCredentials: true },
      );
    },
  },
  Bill: {
    async get_all(props: API.Staff.Apartment.Bill.GetAll.Request) {
      const { limit, page, search, startDate } = props;
      const req: API.Staff.Apartment.Bill.GetAll.Request = {
        limit,
        page,
        search,
        startDate,
      };
      return http.get<API.Staff.Apartment.Bill.GetAll.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Bill.subPath}${API.Staff.Apartment.Bill.GetAll.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async get_monthly(props: API.Staff.Apartment.Bill.GetMonthly.Request) {
      const { roomId, monthYear } = props;
      const req: API.Staff.Apartment.Bill.GetMonthly.Request = { roomId, monthYear };
      return http.post<API.Staff.Apartment.Bill.GetMonthly.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Bill.subPath}${API.Staff.Apartment.Bill.GetMonthly.subPath}`,
        req,
        { withCredentials: true },
      );
    },
    async create_bill(props: API.Staff.Apartment.Bill.Create.Request) {
      const { ...rest } = props;
      const req: API.Staff.Apartment.Bill.Create.Request = { ...rest };
      return http.post<API.Staff.Apartment.Bill.Create.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Bill.subPath}${API.Staff.Apartment.Bill.Create.subPath}`,
        req,
        { withCredentials: true },
      );
    },
    async calculate_room_price(props: API.Staff.Apartment.Bill.CalculateRoomPrice.Request) {
      const { roomId, startDate, endDate } = props;
      const req: API.Staff.Apartment.Bill.CalculateRoomPrice.Request = {
        roomId,
        startDate,
        endDate,
      };
      return http.get<API.Staff.Apartment.Bill.CalculateRoomPrice.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Bill.subPath}${API.Staff.Apartment.Bill.CalculateRoomPrice.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async set_status(props: API.Staff.Apartment.Bill.SetStatus.Request) {
      const { billId, status } = props;
      const req: API.Staff.Apartment.Bill.SetStatus.Request = {
        billId,
        status,
      };
      return http.put<API.Staff.Apartment.Bill.SetStatus.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Bill.subPath}${API.Staff.Apartment.Bill.SetStatus.subPath}`,
        req,
        { withCredentials: true },
      );
    },
    async get_transaction_img_url(props: API.Staff.Apartment.Bill.GetTransactionImgUrl.Request) {
      const { imgPath } = props;
      const req: API.Staff.Apartment.Bill.GetTransactionImgUrl.Request = {
        imgPath,
      };
      return http.get<API.Staff.Apartment.Bill.GetTransactionImgUrl.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Bill.subPath}${API.Staff.Apartment.Bill.GetTransactionImgUrl.subPath}`,
        { params: req, withCredentials: true },
      );
    },
  },
  Booking: {
    async get_all_by_room_id(props: API.Staff.Apartment.Booking.GetAllByRoomId.Request) {
      const { limit, page, roomId, sinceDate, endDate } = props;
      const req: API.Staff.Apartment.Booking.GetAllByRoomId.Request = {
        limit,
        page,
        roomId,
        sinceDate,
        endDate,
      };
      return http.get<API.Staff.Apartment.Booking.GetAllByRoomId.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Booking.subPath}${API.Staff.Apartment.Booking.GetAllByRoomId.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async get_monthly(props: API.Staff.Apartment.Booking.GetCalendarView.Request) {
      const { month, year } = props;
      const req: API.Staff.Apartment.Booking.GetCalendarView.Request = {
        month,
        year,
      };
      return http.get<API.Staff.Apartment.Booking.GetCalendarView.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Booking.subPath}${API.Staff.Apartment.Booking.GetCalendarView.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async get_by_id(props: API.Staff.Apartment.Booking.GetById.Request) {
      const { id } = props;
      const req: API.Staff.Apartment.Booking.GetById.Request = {
        id,
      };
      return http.get<API.Staff.Apartment.Booking.GetById.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Booking.subPath}${API.Staff.Apartment.Booking.GetById.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async set_booking_status(props: API.Staff.Apartment.Booking.SetBookingStatus.Request) {
      const { bookingId, status } = props;
      const req: API.Staff.Apartment.Booking.SetBookingStatus.Request = {
        bookingId,
        status,
      };
      return http.put<API.Staff.Apartment.Booking.SetBookingStatus.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Booking.subPath}${API.Staff.Apartment.Booking.SetBookingStatus.subPath}`,
        req,
        { withCredentials: true },
      );
    },
    async confirm_booking(props: API.Staff.Apartment.Booking.ConfirmBooking.Request) {
      const { bookingId, roomId } = props;
      const req: API.Staff.Apartment.Booking.ConfirmBooking.Request = {
        bookingId,
        roomId,
      };
      return http.put<API.Staff.Apartment.Booking.ConfirmBooking.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.Booking.subPath}${API.Staff.Apartment.Booking.ConfirmBooking.subPath}`,
        req,
        { withCredentials: true },
      );
    },
  },
  IssueReport: {
    async get_all(props: API.Staff.Apartment.IssueReport.GetAll.Request) {
      const { limit, page, search } = props;
      const req: API.Staff.Apartment.IssueReport.GetAll.Request = {
        limit,
        page,
        search,
      };
      return http.get<API.Staff.Apartment.IssueReport.GetAll.Response>(
        `${API.Staff.subRoute}${API.Staff.Apartment.subPath}${API.Staff.Apartment.IssueReport.subPath}${API.Staff.Apartment.IssueReport.GetAll.subPath}`,
        { params: req, withCredentials: true },
      );
    },
  },
};
