import {
  ApartmentRoom,
  ApartmentRoomDailyModal,
  ApartmentRoomModal,
  DetailBookingModalData,
  RoomBillData,
} from "../types/room";

export const initialRoomData: ApartmentRoom = {
  id: "",
  billId: "",
  roomNo: "",
  roomSize: 24,
  roomType: "single",
  rentType: "daily",
  monthlyPrice: 0,
  dailyPrice: 0,
  weekendPrice: 0,
  renter: null,
  dateIn: new Date().getTime(),
  dateOut: new Date().getTime(),
  bookingStatus: null,
};

export const initialRoomModalData: ApartmentRoomModal = {
  id: "",
  roomNo: "",
  rentType: "monthly",
  roomSize: 24,
  roomType: "single",
  monthlyPrice: 0,
  dailyPrice: 0,
  weekendPrice: 0,
  renter: null,
  dateIn: new Date().getTime(),
  dateOut: new Date().getTime(),
};

export const initialRoomBillData: RoomBillData = {
  id: "",
  roomNo: "",
  renter: null,
  dateIn: new Date().getTime(),
  dateOut: new Date().getTime(),
  monthlyPrice: 0,
};

export const initialRoomDailyModalData: ApartmentRoomDailyModal = {
  id: "",
  roomNo: "",
  rentType: "daily",
  roomSize: 24,
  roomType: "single",
  bookingStatus: "canceled",
  monthlyPrice: 0,
  dailyPrice: 0,
  weekendPrice: 0,
  renter: null,
  dateIn: new Date().getTime(),
  dateOut: new Date().getTime(),
};

export const initialDetailBookingModalData: DetailBookingModalData = {
  renter: {
    id: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    lineId: "",
    role: "customer",
    department: "customer",
    username: "",
    active: false,
    birthDate: 0,
    memberExpiryDate: 0,
  },
  roomNo: "",
  dateIn: new Date().getTime(),
  dateOut: new Date().getTime(),
  status: "canceled",
  type: "daily",
};
