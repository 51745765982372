import * as date from "shared-lib/src/utility/time";
import { Logo } from "./Logo";
import { Flex } from "./Container";
import { Button } from "./Button";
import { User } from "../App";
import UserContext from "../Apartment/contexts/userContext";
import { useContext, useEffect, useState } from "react";
import { AdminAPI } from "../Apartment/api/service";
import * as SocketIOClient from "socket.io-client";
import { API, Types } from "shared-lib";
import { CoWorkingAPI } from "../Co-Working/api/service";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import toast from "react-hot-toast";
import { Modal } from "./Modal";
import { convertBookingStatus, convertBookingTimeSlot } from "../Co-Working/functions/convert-type";
import type { BookData } from "../Co-Working/types/book-room";
import { initialBookingData } from "../Co-Working/data/init-booking";
import { initialUserBookingData } from "../Co-Working/data/init-user";
import { initialRoomData } from "../Co-Working/data/init-room";
import { BillStatus } from "../Apartment/types/bill";
import { initialRoomData as intitialApartmentRoomData } from "../Apartment/data/init-room";
import { initialRenter } from "../Apartment/data/init-renter";
import { converApartmentDailyRoomType } from "../Apartment/functions/convert-types";
import { RoomDailyDropdownList } from "./Dropdown";
dayjs.extend(relativeTime);

type UserNotification = Types.Notification.Type;
type ApartmentBookingResponse = API.Staff.Apartment.Booking.GetById.Response["data"];
type Booking = Pick<BookData, "user" | "room" | "booking" | "transactionImg" | "id" | "paidDate">;
type ApartmentBooking = Pick<
  ApartmentBookingResponse,
  | "room"
  | "transactionImg"
  | "id"
  | "paidDate"
  | "renter"
  | "status"
  | "dateIn"
  | "dateOut"
  | "roomNo"
>;

const fetchApartmentBookingData = async (props: { bookingId: string }) => {
  const { bookingId } = props;
  try {
    const bookingResponse = await AdminAPI.Booking.get_by_id({ id: bookingId });
    if (bookingResponse.data.ok) {
      const {
        room,
        transactionImg,
        id,
        paidDate,
        renter: user,
        status,
        dateIn,
        dateOut,
        roomNo,
      } = bookingResponse.data.data;
      const res: ApartmentBooking = {
        room,
        transactionImg,
        id,
        paidDate,
        renter: user,
        status,
        dateIn,
        dateOut,
        roomNo,
      };
      return res;
    }
  } catch (e) {
    toast.error("เกิดปัญหาในการเรียกข้อมูล");
  }
};

const fetchCoWorkingBookingData = async (props: { bookingId: string }) => {
  const { bookingId } = props;
  try {
    const bookingResponse = await CoWorkingAPI.Booking.get_by_id({ id: bookingId });
    if (bookingResponse.data.ok) {
      const { user, room, booking, transactionImg, id, paidDate } = bookingResponse.data.data;
      const res: Booking = { user, room, booking, transactionImg, id, paidDate };
      return res;
    }
  } catch (e) {
    toast.error("เกิดปัญหาในการเรียกข้อมูล");
  }
};

const getApartmentTransactionImgUrl = async (props: { transactionImg: string | null }) => {
  const { transactionImg } = props;
  try {
    const response = await AdminAPI.Bill.get_transaction_img_url({
      imgPath: transactionImg ?? "",
    });
    if (response.data.ok) {
      return response.data.data.imgUrl;
    }
  } catch (e) {
    toast.error("เกิดปัญหาในการเรียกข้อมูล");
  }
};

const getTransactionImgUrl = async (props: { transactionImg: string | null }) => {
  const { transactionImg } = props;
  try {
    const response = await CoWorkingAPI.Bill.get_transaction_img_url({
      imgPath: transactionImg ?? "",
    });
    if (response.data.ok) {
      return response.data.data.imgUrl;
    }
  } catch (e) {
    toast.error("เกิดปัญหาในการเรียกข้อมูล");
  }
};

const setBillStatus = async (billId: string, status: BillStatus) => {
  const onEdit = toast.loading("กำลังแก้ไขข้อมูลบิล...");
  try {
    const editBillResponse = await CoWorkingAPI.Bill.set_status({ billId, status });
    if (editBillResponse.data.ok) {
      toast.remove(onEdit);
      toast.success("แก้ไขข้อมูลเสร็จสิ้น");
      return true;
    } else {
      toast.remove(onEdit);
      toast.error("ไม่สามารถแก้ไขข้อมูลได้");
    }
  } catch (e) {
    toast.remove(onEdit);
    toast.error("ไม่สามารถแก้ไขข้อมูลได้");
  }
};

const ApartmentBookingModal = (props: {
  bookingData: ApartmentBooking;
  transactionImgUrl?: string;
  hide: () => void;
}) => {
  const { bookingData, transactionImgUrl, hide } = props;
  const [roomIdSelect, setRoomIdSelect] = useState<string>("");

  return (
    <div className="notification-modal">
      <Modal
        title="ข้อมูลการจอง"
        close={hide}
        footer={
          bookingData.paidDate && bookingData.transactionImg && bookingData.status === "waiting" ? (
            <>
              <Button type="submit" onClick={() => {}} colour="black">
                <div className="w-150px fw-bold">ยืนยันการชำระเงิน</div>
              </Button>
              <Button type="submit" onClick={() => {}} colour="black">
                <div className="w-150px fw-bold">ยกเลิกการจอง</div>
              </Button>
            </>
          ) : undefined
        }
        footerAlign="center"
      >
        <div>
          <p>{bookingData.roomNo}</p>
          <p>{converApartmentDailyRoomType(bookingData.room.roomType)}</p>

          <div className="text-center">
            <p>ข้อมูลผู้เข้าพัก</p>
          </div>
          <p>
            สถานะ <span className="tab-25"></span> : <span className="tab-25"></span>
            {convertBookingStatus(bookingData.status)}
          </p>
          <div className="info-box row">
            <div className="row m-0 p-2 align-items-center justify-content-center text-start">
              <div className="col-4">ชื่อ-นามสกุล:</div>
              <div className="col-4">
                {bookingData.renter.firstName + " " + bookingData.renter.lastName}
              </div>
            </div>
            <div className="row m-0 p-2 align-items-center justify-content-center text-start">
              <div className="col-4">เบอร์โทรศัพท์:</div>
              <div className="col-4">{bookingData.renter.phoneNumber}</div>
            </div>
            <div className="row m-0 p-2 align-items-center justify-content-center text-start">
              <div className="col-4">อีเมล์:</div>
              <div className="col-4">{bookingData.renter.email ?? "-"}</div>
            </div>
          </div>
          <div className="row m-0 p-2 align-items-center justify-content-center text-start">
            <div className="col-3">วันที่เข้าพัก:</div>
            <div className="col-3">{date.getLocaleDateString(bookingData.dateIn)}</div>
          </div>
          <div className="row m-0 p-2 align-items-center justify-content-center text-start">
            <div className="col-3">วันที่ออก:</div>
            <div className="col-3">{date.getLocaleDateString(bookingData.dateOut)}</div>
          </div>
          {bookingData.status === "waiting" ? (
            <>
              <div className="mt-3 text-center">
                <p>หลักฐานการชำระเงิน</p>
                <div className="info-box">
                  {transactionImgUrl ? (
                    <img className="img-fluid" src={transactionImgUrl} />
                  ) : (
                    <div className="p-5">ไม่พบหลักฐานการชำระเงิน</div>
                  )}
                </div>
              </div>
              {bookingData.paidDate ? (
                <>
                  <div className="row m-0 p-2 align-items-center justify-content-center text-start">
                    <div className="col-3">วันที่ชำระเงิน:</div>
                    <div className="col-3">{date.getLocaleDateString(bookingData.paidDate)}</div>
                  </div>
                  <div className="row m-0 p-2 align-items-center justify-content-center text-start">
                    <div className="col-3">เวลาที่ชำระเงิน:</div>
                    <div className="col-3">{date.getLocaleTimeString(bookingData.paidDate)}</div>
                  </div>
                  <div className="mt-3 text-center">เลขห้องที่เข้าพัก</div>
                  <RoomDailyDropdownList setSelected={setRoomIdSelect} />
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </Modal>
    </div>
  );
};

const CoWorkingBookingModal = (props: {
  bookingData: Booking;
  paidDate?: number;
  transactionImgUrl?: string;
  hide: () => void;
}) => {
  const { bookingData, transactionImgUrl, hide, paidDate } = props;
  const bookedTimeSlot = convertBookingTimeSlot({ timeSlot: bookingData.booking.timeSlot });

  return (
    <div className="notification-modal">
      <Modal
        title="ข้อมูลการจอง"
        close={hide}
        footer={
          <>
            <Button
              type="submit"
              onClick={async () => {
                const finish = await setBillStatus(bookingData.id, "paid");
                if (finish) {
                  hide;
                }
              }}
              disabled={false}
              colour="black"
            >
              <div className="w-150px fw-bold">ยืนยันการชำระเงิน</div>
            </Button>
            <Button
              type="submit"
              onClick={async () => {
                const finish = await setBillStatus(bookingData.id, "expired");
                if (finish) {
                  hide;
                }
              }}
              disabled={false}
              colour="black"
            >
              <div className="w-150px fw-bold">ยกเลิกการจอง</div>
            </Button>
          </>
        }
        footerAlign="center"
      >
        <div>
          <div className="text-center m-2">
            <p>{bookingData.room.name}</p>
          </div>
          <div className="row m-2 justify-content-center">
            <div className="col-3 text-end">สถานะ</div>
            <div className="col-1">:</div>
            <div className="col-3 text-start">
              {convertBookingStatus(bookingData.booking.status)}
            </div>
          </div>
          <div className="text-center m-3">
            <p>ข้อมูลผู้จอง</p>
          </div>
          <div className="info-box">
            <div className="row m-2 justify-content-evenly">
              <div className="row m-2 justify-content-evenly">
                <div className="col-4 m-2 text-end">ชื่อ-นามสกุล:</div>
                <div className="col-4 m-2 text-start">
                  {`${bookingData.user.firstName} ${bookingData.user.lastName}`}
                </div>
              </div>
              <div className="row m-2 justify-content-evenly text-start">
                <div className="col-4 m-2 text-end">เบอร์โทรศัพท์:</div>
                <div className="col-4 m-2 text-start"> {`${bookingData.user.phoneNumber}`}</div>
              </div>
              <div className="row m-2 justify-content-evenly text-start">
                <div className="col-4 m-2 text-end">อีเมล์:</div>
                <div className="col-4 m-2 text-start"> {`${bookingData.user.email ?? "-"}`}</div>
              </div>
              <div className="row m-2 justify-content-evenly text-start">
                <div className="col-4 m-2 text-end">Line ID:</div>
                <div className="col-4 m-2 text-start"> {`${bookingData.user.lineId ?? "-"}`}</div>
              </div>
            </div>
          </div>
          <div className="m-2">
            <div className="row m-2 justify-content-evenly">
              <div className="col-4 m-2 text-end">วันที่จอง:</div>
              <div className="col-4 m-2 text-start">
                {date.getLocaleDateString(bookingData.booking.timeSlot[0].startTime)}
              </div>
            </div>
            <div className="row m-2 justify-content-evenly">
              <div className="col-4 m-2 text-end">ช่วงเวลาที่จอง:</div>
              <div className="col-4 m-2 text-start">
                {bookedTimeSlot.map((slot, index) => {
                  return <div key={index}>{slot}</div>;
                })}
              </div>
            </div>
          </div>
          <div className="text-center m-3">
            <p>หลักฐานการชำระเงิน</p>
          </div>
          <div className="info-box">
            {transactionImgUrl ? (
              <>
                <img className="img-fluid" src={transactionImgUrl} />
              </>
            ) : (
              <div className="p-5">ไม่พบหลักฐานการชำระเงิน</div>
            )}
          </div>
          <div className="row m-0 p-2 align-items-center justify-content-center text-start">
            <div className="col-3">วันที่ชำระเงิน:</div>
            <div className="col-3">{paidDate ? date.getLocaleDateString(paidDate) : "-"}</div>
          </div>
          <div className="row m-0 p-2 align-items-center justify-content-center text-start">
            <div className="col-3">เวลาที่ชำระเงิน:</div>
            <div className="col-3">{paidDate ? date.getLocaleTimeString(paidDate) : "-"}</div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const DisplayNotifications = (props: { notification: UserNotification }) => {
  const { notification } = props;
  const { message, bookingId, timestamp, department } = notification;
  const [showModal, setShowModal] = useState(false);
  const [apartmentBookingData, setApartmentBookingData] = useState<ApartmentBooking>({
    room: intitialApartmentRoomData,
    roomNo: "",
    paidDate: null,
    id: "",
    transactionImg: "",
    renter: initialRenter,
    status: "waiting",
    dateIn: 0,
    dateOut: 0,
  });
  const [bookingData, setBookingData] = useState<Booking>({
    booking: initialBookingData,
    user: initialUserBookingData,
    room: initialRoomData,
    transactionImg: "",
    id: "",
    paidDate: null,
  });
  const [imageUrl, setImageUrl] = useState("");

  const fetchApartmentModalData = async () => {
    const booking = await fetchApartmentBookingData({ bookingId });
    if (booking) {
      if (booking.transactionImg) {
        const bookingImgUrl = await getApartmentTransactionImgUrl({
          transactionImg: booking.transactionImg,
        });
        if (bookingImgUrl) {
          setImageUrl(bookingImgUrl);
        }
        setApartmentBookingData(booking);
        return true;
      }
      setApartmentBookingData(booking);
      return true;
    }
  };

  const fetchModalData = async () => {
    const booking = await fetchCoWorkingBookingData({ bookingId });
    if (booking) {
      if (booking.transactionImg) {
        const bookingImgUrl = await getTransactionImgUrl({
          transactionImg: booking.transactionImg,
        });
        if (bookingImgUrl) {
          setImageUrl(bookingImgUrl);
        }
      }
      setBookingData(booking);
      return true;
    }
  };

  return (
    <>
      <div className="row p-1 justify-content-between align-items-center">
        <div className="col-4 p-1">
          <i className="fs-1 fas fa-exclamation-circle my-2" />
          <div>{dayjs(timestamp).fromNow()}</div>
        </div>
        <div className="col-8">
          <div className="my-1 text-start">{message}</div>
          <div className="text-end">
            <Button
              type="submit"
              colour="black"
              onClick={async () => {
                /** clear state before fetch a new notification data */
                setBookingData({
                  booking: initialBookingData,
                  user: initialUserBookingData,
                  room: initialRoomData,
                  transactionImg: "",
                  id: "",
                  paidDate: null,
                });
                setImageUrl("");
                if (department === "apartment") {
                  const finish = await fetchApartmentModalData();
                  if (finish) {
                    setShowModal(true);
                  }
                } else {
                  const finish = await fetchModalData();
                  if (finish) {
                    setShowModal(true);
                  }
                }
              }}
            >
              <div className="fw-bold">ดูรายละเอียด</div>
            </Button>
          </div>
        </div>
      </div>
      {showModal ? (
        department === "apartment" ? (
          <ApartmentBookingModal
            bookingData={apartmentBookingData}
            transactionImgUrl={imageUrl ?? undefined}
            hide={() => {
              setShowModal(false);
            }}
          />
        ) : (
          <CoWorkingBookingModal
            bookingData={bookingData}
            transactionImgUrl={imageUrl ?? undefined}
            paidDate={bookingData.paidDate ?? undefined}
            hide={() => {
              setShowModal(false);
            }}
          />
        )
      ) : (
        ""
      )}
    </>
  );
};

const NavbarMenu = (props: {
  children: JSX.Element[];
  socket: SocketIOClient.Socket | null;
  status: User;
}) => {
  const { socket, status } = props;
  const user = useContext(UserContext);
  const [showDisplayNotifications, setShowDisplayNotifications] = useState(false);
  const [notifications, setNotifications] = useState<UserNotification[]>([]);

  const Logout = async () => {
    const LogoutResponse = await AdminAPI.User.log_out();
    if (LogoutResponse) {
      user?.setAuth(false);
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on("notiEvent", (data) => {
        setNotifications((prev) => [
          {
            id: data.id,
            message: data.message,
            bookingId: data.bookingId,
            department: data.department,
            timestamp: data.timestamp,
            isRead: data.isRead,
          },
          ...prev,
        ]);
      });
      return () => {
        socket.disconnect();
      };
    }
  }, [socket]);

  useEffect(() => {
    if (status === "CO-WORKING") {
      (async () => {
        try {
          const notificationResponse = await CoWorkingAPI.Notification.get_all({});
          if (notificationResponse.data.ok) {
            setNotifications(notificationResponse.data.data);
          }
        } catch (e) {
          console.log(e);
        }
      })();
    } else if (status === "APARTMENT") {
      (async () => {
        try {
          const notificationResponse = await AdminAPI.Notification.get_all({});
          if (notificationResponse.data.ok) {
            setNotifications(notificationResponse.data.data);
          }
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, [status]);

  return (
    <div className="fixed-nav-bar">
      <div className="p-3 x-bg-black x-text-white x-navbar">
        <Flex justifyContent="start" alignItems="center" padding={4}>
          <div className="px-1">
            <a href="/">
              <Logo color="WHITE" height={24} />
            </a>
          </div>
          <Flex justifyContent="start" alignItems="center" padding={16}>
            {props.children}
          </Flex>
          <div className="flex-grow-1">
            <Flex justifyContent="end" alignItems="center">
              <Button
                onClick={() => {
                  setShowDisplayNotifications(!showDisplayNotifications);
                }}
              >
                <>
                  <div className=" noti-icon">
                    <i className="fas fa-bell" />
                  </div>
                  <div className="noti-counter fw-bold">{notifications.length}</div>
                </>
              </Button>
              <Button onClick={Logout}>
                <i className="fas fa-sign-in-alt" />
              </Button>
            </Flex>
          </div>
        </Flex>
      </div>
      {showDisplayNotifications && notifications.length > 0 ? (
        <div className="notification">
          {notifications.map((noti, index) => {
            return (
              <div key={index} className="notification-info">
                <DisplayNotifications notification={noti} />
              </div>
            );
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const Navbar = (props: { status: User; socket: SocketIOClient.Socket | null }) => {
  if (props.status !== "GUEST") {
    if (props.status === "APARTMENT") {
      return (
        <NavbarMenu socket={props.socket} status={props.status}>
          <div>
            <Button to="/">
              <span>หน้าหลัก</span>
            </Button>
          </div>
          <div>
            <Button to="/manage-room">
              <span>จัดการห้องพัก</span>
            </Button>
          </div>
          <div>
            <Button to="/manage-discount">
              <span>จัดการส่วนลด</span>
            </Button>
          </div>
          <div>
            <Button to="/manage-member">
              <span>จัดการสมาชิก</span>
            </Button>
          </div>
          <div>
            <Button to="/product">
              <span>สินค้าเพิ่มเติม</span>
            </Button>
          </div>
          <div>
            <Button to="/manage-problem">
              <span>จัดการคำร้อง</span>
            </Button>
          </div>
          <div>
            <Button to="/manage-bill">
              <span>จัดการบิล</span>
            </Button>
          </div>

          {/* <div>
            <Button to="/print-summary">
              <span>พิมพ์ใบสรุปรายการ</span>
            </Button>
          </div> */}
        </NavbarMenu>
      );
    }
    if (props.status === "CO-WORKING") {
      return (
        <NavbarMenu socket={props.socket} status={props.status}>
          <div>
            <Button to="/">
              <span>หน้าหลัก</span>
            </Button>
          </div>
          <div>
            <Button to="/manage-room">
              <span>จัดการห้อง</span>
            </Button>
          </div>
          <div>
            <Button to="/manage-booking">
              <span>จัดการจองห้อง</span>
            </Button>
          </div>
          <div>
            <Button to="/manage-member">
              <span>จัดการสมาชิก</span>
            </Button>
          </div>
          <div>
            <Button to="/manage-discount">
              <span>จัดการส่วนลด</span>
            </Button>
          </div>
          <div>
            <Button to="/product">
              <span>สินค้าเพิ่มเติม</span>
            </Button>
          </div>
          <div>
            <Button to="/manage-bill">
              <span>จัดการบิล</span>
            </Button>
          </div>
          {/* <div>
            <Button to="/print-summary">
              <span>พิมพ์ใบสรุปรายการ</span>
            </Button>
          </div> */}
        </NavbarMenu>
      );
    }
    if (props.status === "MANAGER") {
      return (
        <NavbarMenu socket={props.socket} status={props.status}>
          <div>
            <Button to="/">
              <span>หน้าหลัก</span>
            </Button>
          </div>
          <div>
            <Button to="/manage-staff">
              <span>จัดการ Staff</span>
            </Button>
          </div>
          {/* <div>
            <Button to="/print-summary-report-apartment">
              <span>พิมพ์ใบสรุปรายการ Apartment</span>
            </Button>
          </div>
          <div>
            <Button to="/print-summary-report-co-working">
              <span>พิมพ์ใบสรุปรายการ Co-Working</span>
            </Button>
          </div>
          <div>
            <Button to="/print-summary-report-staff">
              <span>พิมพ์ใบสรุปรายการ Staff</span>
            </Button>
          </div> */}
        </NavbarMenu>
      );
    }
  }
  return <></>;
};

export { Navbar };
