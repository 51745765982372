//const COPYRIGHT = `COPYRIGHT ${(new Date().getFullYear())}`;

const Footer = () => {
  return (
    <div className="footer">
      <div>
        <span>COPYRIGHT</span>
        <i className="fas fa-copyright pe-1" />
        <span>{new Date().getFullYear()}</span>
        <span className="ps-1">XKHANEK.</span>
      </div>
    </div>
  );
};

export { Footer };
