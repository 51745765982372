import { Link } from "react-router-dom";

type ButtonType = "submit" | "button" | "reset";

type ButtonProps = {
  colour?: "yellow" | "black";
  children: JSX.Element;
  onClick?: () => void;
  disabled?: boolean;
  type?: ButtonType;
};

const InternalButton = (props: {
  bgColour?: string;
  textColour?: string;
  children: JSX.Element;
  onClick?: () => void;
  disabled?: boolean;
  type?: ButtonType;
}) => {
  const { bgColour, textColour, children, onClick, disabled, type } = props;
  return (
    <button
      {...{ type, disabled }}
      className={`btn border-0 rounded-0 ${
        bgColour ? "x-bg-" + bgColour + " shadow" : "py-0"
      }`}
      onClick={onClick}
    >
      <div className={`${textColour ? "x-text-" + textColour : ""}`}>
        {children}
      </div>
    </button>
  );
};

const ColouredButton = (props: ButtonProps) => {
  const { colour } = props;
  if (colour === "black") {
    return <InternalButton bgColour="black" textColour="yellow" {...props} />;
  }
  if (colour === "yellow") {
    return <InternalButton bgColour="yellow" textColour="black" {...props} />;
  }
  return <InternalButton textColour="light" {...props} />;
};

const Button = (props: { to?: string } & ButtonProps) => {
  const { to } = props;
  const buttonEl = <ColouredButton {...{ ...props }} />;
  if (to) {
    return <Link to={to}>{buttonEl}</Link>;
  }
  return buttonEl;
};

const AddCostButton = (props: {
  bgColour?: string;
  textColour?: string;
  children: JSX.Element;
  onClick?: () => void;
  disabled?: boolean;
  type?: ButtonType;
}) => {
  const { bgColour, textColour, children, onClick, disabled, type } = props;
  return (
    <button
      {...{ type, disabled }}
      className={`btn border-0 rounded-3 ${
        bgColour ? "x-bg-" + bgColour + " shadow" : "py-0"
      }`}
      onClick={onClick}
    >
      <div className={`${textColour ? "x-text-" + textColour : ""}`}>
        {children}
      </div>
    </button>
  );
};

export { Button, ColouredButton, AddCostButton };
