// import { Modify } from "../../";
import * as API from "../../api";
import * as Types from "../../main";

export const subPath = "/co-working";

export namespace Bill {
  export const subPath = "/bill";

  export type CustomBill = Omit<
    Types.Bill.Type<"co-working">,
    "userId" | "seller" | "confirmor"
  > & {
    user: API.User.CustomType.Basic;
    seller: API.User.CustomType.Basic;
    confirmor: API.User.CustomType.Basic | null;
  };

  /**
   * Get the all bill info.
   *
   * - path: /staff/co-working/bill/get-all
   * - method: GET
   * @ for co-working staff
   */
  export namespace GetAll {
    export const subPath = "/get-all";
    /** querystring */
    export type Request = API.Pagination & {
      /** billNo, roomNo */
      search?: string;
      startDate: number;
    };
    export type Response = API.ResponsePayload<CustomBill[]>;
  }

  export namespace GetByBookingId {
    export const subPath = "/get-by-booking-id";
    /** querystring */
    export type Request = {
      bookingId: string;
    };
    export type Response = API.ResponsePayload<CustomBill>;
  }

  /*
   * Calculate room price.
   *
   * - path: /staff/co-working/bill/calculate-room-price
   * - method: POST
   * @ for apartment staff
   */
  export namespace CalculateRoomPrice {
    type RoomPrices = {
      "1hour": number;
      "3hour": number | null;
      "6hour": number | null;
      allDay: number | null;
    };
    export const subPath = "/calculate-room-price";
    /** body */
    export type Request = {
      timeSlot: Types.TimeSlot[];
      prices: RoomPrices;
      type: Types.CoWorking.RoomType.Def;
      discountCode?: string;
    };
    export type Response = API.ResponsePayload<{ price: number }>;
  }

  /*
   * Create the new bill.
   *
   * - path: /staff/co-working/bill/create
   * - method: POST
   * @ for apartment staff
   */
  export namespace Create {
    export const subPath = "/create";
    /** body */
    export type Request = {
      userId: string;
      expiryDate: number | null;
      purchasedProducts: Omit<
        Types.Bill.PurchasedProduct,
        "department" | "total" | "subTotal" | "createdTime"
      >[];
      method: Types.Bill.PaymentMethod.Def;
      paid: boolean;
      code: string | null;
      type: Types.Bill.Type<"co-working">["type"];
    };
    export type Response = API.ResponsePayload<{ newBillNo: string }>;
  }

  /**
   * ✅
   * Get the image of transaction bill
   *
   * - path: /staff/co-working/bill/get-transaction-img-url
   * - method: GET
   * @ for apartment staff
   */
  export namespace GetTransactionImgUrl {
    export const subPath = "/get-transaction-img-url";

    export type Request = {
      /** querystring */
      imgPath: string;
    };

    export type Response = API.ResponsePayload<{ imgUrl: string }>;
  }

  /*
   * Set the bill to expired or paid.
   * also auto set all bookings to "confirmed"
   *
   * - path: /staff/co-working/bill/set-status
   * - method: PUT
   * @ for co-working staff
   */
  export namespace SetStatus {
    export const subPath = "/set-status";

    export type AllowStatus = Exclude<Types.Bill.Status.Def, "unpaid">;
    /** body */
    export type Request = {
      billId: string;
      status: AllowStatus;
    };

    export type Response = API.ResponsePayload<Request, "">;
  }
}

export namespace Room {
  export const subPath = "/room";
  /**/

  export type RoomResponse = Types.CoWorking.Room;

  /**
   * Get all rooms.
   *
   * - path: /staff/co-working/room/get-all
   * - method: "GET"
   *  @ for co-working staff
   */
  export namespace GetAll {
    export const subPath = "/get-all";

    export type Request = API.Pagination & {
      // Partial<Types.MonthYear> & {
      // bookingDate?: number;

      /** querystring */
      /** room name */
      search?: string;
      type?: Types.CoWorking.RoomType.Def;

      // active?: boolean;
    };
    export type Response = API.ResponsePayload<RoomResponse[]>;
  }

  /**
   * Get a room.
   *
   * - path: /staff/co-working/room/get-by-id
   * - method: "GET"
   *  @ for co-working staff
   */
  export namespace GetById {
    export const subPath = "/get-by-Id";
    /** querystring */
    export type Request = { id: string };
    export type Response = API.ResponsePayload<Types.CoWorking.Room>;
  }

  /**
   * Edit a room.
   *
   * - path: /staff/co-working/room/edit
   * - method: "PUT"
   *  @ for co-working staff
   */
  export namespace Edit {
    export const subPath = "/edit";
    /** body */
    export type Request = { id: string } & Partial<
      Pick<Types.CoWorking.Room, "type" | "capacity" | "name">
    > & {
        prices?: Partial<Types.CoWorking.Room["prices"]>;
      };
    export type Response = API.ResponsePayload<Request, "update co-working room successfully">;
  }

  /**
   * Get reserved time slots of the room.
   *
   * - path: /staff/co-working/room/get-reserved-timeslot
   * - method: "GET"
   * @ for registered customer.
   */
  export namespace GetReservedTimeSlot {
    export const subPath = "/get-reserved-timeslot";
    /** querystring */
    // export type Request = API.Pagination & {
    //   roomId: string;
    //   date: number;
    // };
    export type Request = {
      roomId: string;
      date: number;
    };
    export type Response = API.ResponsePayload<Types.TimeSlot[]>;
  }
}

export namespace Booking {
  export const subPath = "/booking";

  export type BookingResponse = Omit<
    Types.Bill.Type<"co-working">,
    "userId" | "seller" | "booking"
  > & {
    booking: Types.CoWorking.Booking;
    user: API.User.CustomType.Basic | Types.Guest.Type;
    room: Pick<Types.CoWorking.Room, "id" | "name">;
  };

  /**
   * Get all booking.
   *
   * - path: /staff/co-working/booking/get-all
   * - method: "GET"
   *  @ for co-working staff
   */
  export namespace GetAll {
    export const subPath = "/get-all";
    export type Request = API.Pagination &
      Partial<Types.MonthYear> & {
        bookingDate?: number;
        roomId?: string;
        /** booker'name or phone number */
        search?: string;
        active?: boolean;
      };
    export type Response = API.ResponsePayload<BookingResponse[]>;
  }

  /**
   * Get a booking.
   *
   * - path: /staff/co-working/booking/get-by-id
   * - method: "GET"
   *  @ for co-working staff
   */
  export namespace GetById {
    export const subPath = "/get-by-id";
    export type Request = {
      id: string;
    };
    export type Response = API.ResponsePayload<BookingResponse>;
  }

  /**
   * Book a daily co-working rooms.
   *
   * - path: /staff/co-working/booking/create
   * - method: "POST"
   * @ for registered customer.
   */
  export namespace Create {
    export const subPath = "/create";
    /** body */
    export type Request = {
      userId: string;
      roomId: string;
      timeSlot: Types.TimeSlot[];
      bookingDate: number;
      code?: string;
      paymentMethod: Types.Bill.PaymentMethod.Def;
    };
    export type Response = API.ResponsePayload<
      Request,
      "book your co-working space room successfully"
    >;
  }

  /**
   * ConfirmBooking.
   *
   * - path: /staff/co-working/booking/confirm-booking
   * - method: "PUT"
   *  @ for co-working staff
   */
  export namespace ConfirmBooking {
    export const subPath = "/confirm-booking";
    export type Request = {
      /** body */
      bookingId: string;
    };
    export type Response = API.ResponsePayload<Request, "">;
  }

  /**
   * Set a booking status.
   *
   * - path: /staff/co-working/booking/set-status
   * - method: "PUT"
   *  @ for co-working staff
   */
  export namespace SetStatus {
    export const subPath = "/set-status";
    export type Request = {
      /** body */
      id: string;
      status: Exclude<Types.BookingStatus.Def, "waiting">;
    };
    export type Response = API.ResponsePayload<Request, "">;
  }
}

export namespace Member {
  export const subPath = "/member";

  /**
   * Get all members
   *
   * - path: /staff/co-working/member/get-all-members
   * - method: "GET"
   *  @ for co-working staff
   */
  export namespace GetAllMember {
    export const subPath = "/get-all-members";
    /** querystring */
    export type Request = API.Pagination & {
      search?: string;
      active?: boolean;
    };
    export type Response = API.ResponsePayload<
      Pick<
        Types.User.Type,
        | "id"
        | "firstName"
        | "lastName"
        | "email"
        | "phoneNumber"
        | "lineId"
        | "active"
        | "userNo"
        | "memberExpiryDate"
      >[]
    >;
  }

  /**
   * Set a exclusive co-working membership.
   *
   * - path: /staff/co-working/member/set-membership
   * - method: "PUT"
   * @ for co-working-staff
   */
  export namespace SetMembership {
    export const subPath = "/set-member-ship";
    /** body */
    export type Request = {
      id: string;
      memberExpiryDate: number;
    };
    export type Response = API.ResponsePayload<{
      /**/
    }>;
  }

  /**
   * Edit a member
   *
   * - path: /staff/co-working/member/edit
   * - method: "PUT"
   *  @ for co-working staff
   */
  export namespace EditMember {
    export type DefaultData = Pick<Types.User.Type, "id"> &
      Partial<Pick<Types.User.Type, "firstName" | "lastName" | "phoneNumber" | "email" | "lineId">>;
    //
    export const subPath = "/edit";
    /** body */
    export type Request = DefaultData;
    export type Response = API.ResponsePayload<null, "edit member info successfully">;
  }

  /**
   * Set status of user (active or inactive)
   *
   * - path: /staff/co-working/member/set-status
   * - method: "PUT"
   * @ for co-working staff
   */
  export namespace SetStatus {
    //
    export const subPath = "/set-status";
    /** body */
    export type Request = {
      id: string;
      active: boolean;
    };
    export type Response = API.ResponsePayload<Request, "set status member successfully">;
  }
}

export namespace Notification {
  export const subPath = "/notification";

  /**
   *
   * Get all apartment daily booking notifications.
   *
   * - path: /staff/co-working/notification/get-all
   * - method: GET
   * @ for co-working staff
   */
  export namespace GetAll {
    export const subPath = "/get-all";
    /** querystring */
    export type Request = {
      /** */
    };
    export type Response = API.ResponsePayload<
      Types.Notification.Type[],
      "get all notifications successfully"
    >;
  }

  /**
   *
   * Set notification is read.
   *
   * - path: /staff/co-working/notification/set-is-read
   * - method: PUT
   * @ for co-working staff
   */
  export namespace SetIsRead {
    export const subPath = "/set-is-read";
    /** body */
    export type Request = {
      notiId: string;
      isRead: boolean;
    };
    export type Response = API.ResponsePayload<Request, "set status of notification successfully">;
  }
}

export namespace Product {
  export const subPath = "/product";

  /**
   * Get additional co-working products/services.
   *
   * - path: /staff/co-working/products/get-all
   * - method: GET
   * @ for co-working staff
   */
  export namespace GetAll {
    export const subPath = "/get-all";
    /** querystring */
    export type Request = API.Pagination & {
      search?: string;
    };
    export type Response = API.ResponsePayload<
      Types.Product.Type[],
      "get all product successfully"
    >;
  }

  /**
   * Add additional co-working product/service.
   *
   * - path: /staff/co-working/product/add-one
   * - method: POST
   * @ for co-working staff
   */
  export namespace AddOne {
    export const subPath = "/add-one";
    /** body */
    export type Request = Omit<Types.Product.Type, "id" | "department">;
    export type Response = API.ResponsePayload<{
      /**/
    }>;
  }

  /**
   * Edit an additional co-working product/service.
   *
   * - path: /staff/co-working/product/edit
   * - method: PUT
   * @ for co-working staff
   */
  export namespace Edit {
    export const subPath = "/edit";
    /** body */
    export type Request = { id: string } & Partial<Omit<Types.Product.Type, "id" | "department">>;
    export type Response = API.ResponsePayload<{
      /**/
    }>;
  }

  /**
   * Un-active additional co-working products/services.
   *
   * - path:  /staff/co-working/product/remove
   * - method: DELETE
   * @ for co-working staff
   */
  export namespace Remove {
    export const subPath = "/remove";
    /** querystring */
    export type Request = { id: string };
    export type Response = API.ResponsePayload<{
      /**/
    }>;
  }
}

export namespace Discount {
  export const subPath = "/discount";

  /**
   * Add co-working discount.
   *
   * - path: /staff/co-working/discount/add
   * - method: POST
   * @ for co-working staff
   */
  export namespace Add {
    export const subPath = "/add";
    /** body */
    export type Request = Omit<Types.Discount.Type, "id" | "department">;
    export type Response = API.ResponsePayload<{
      /**/
    }>;
  }

  /**
   * Get co-working discounts info.
   *
   * - path: /staff/co-working/discount/get-all
   * - method: GET
   * @ for co-working staff
   */
  export namespace GetAll {
    export const subPath = "/get-all";
    /** querystring */
    export type Request = API.Pagination & {
      search?: string;
    };
    export type Response = API.ResponsePayload<
      Types.Discount.Type[],
      "get all discount successfully"
    >;
  }

  /**
   * Get co-working discounts info.
   *
   * - path: /staff/co-working/discount/get-one
   * - method: GET
   * @ for co-working staff
   */
  export namespace GetOne {
    export const subPath = "/get-one";
    /** querystring */
    export type Request = { id: string };
    export type Response = API.ResponsePayload<Types.Discount.Type>;
  }

  /**
   * Edit co-working discount info.
   *
   * - path: /staff/co-working/discount/edit
   * - method: PUT
   * @ for co-working staff
   */
  export namespace Edit {
    export const subPath = "/edit";
    /** body */
    export type Request = {
      id: string;
      department: Types.Department.Def;
      type: Types.Discount.Discounting.Def;
    } & Partial<Omit<Types.Discount.Type, "id" | "department" | "type">>;
    export type Response = API.ResponsePayload<{
      //
    }>;
  }

  /**
   * Remove co-working discount.
   *
   * - path: /staff/co-working/discount/remove
   * - method: DELETE
   * @ for co-working staff
   */
  export namespace Remove {
    export const subPath = "/remove";
    /** querystring */
    export type Request = { id: string };
    export type Response = API.ResponsePayload<{
      //
    }>;
  }

  /**
   * Try apply a discount code.
   *
   * - path: /staff/co-working/discount/try-apply
   * - method: POST
   * @ for co-working staff
   */
  export namespace TryApply {
    export const subPath = "/try-apply";

    export type RequestForDiscountingItem = {
      /** for convenient front-end item name debugging.
       *
       * can be
       * - name of items.
       * - auto generated id.
       */
      name: string;
      amount: number;
    };

    /** body */
    export type Request = {
      code: string;
      items: RequestForDiscountingItem[];
    };

    export type Response = API.ResponsePayload<{
      items: (RequestForDiscountingItem & { discounted: number })[];
      name: string;
    }>;
  }
}
