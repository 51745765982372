import { UserBookingData } from "../types/book-room";

export const initialUserBookingData: UserBookingData = {
  id: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  lineId: "",
  role: "customer",
  department: "customer",
  username: "",
  active: false,
  birthDate: new Date().getTime(),
  memberExpiryDate: 0,
};
