import http from "../../api/http";
import * as API from "shared-lib/src/types/api";

export const CoWorkingAPI = {
  Room: {
    async get_all(props: API.Staff.CoWorking.Room.GetAll.Request) {
      const { limit, page, search, type } = props;
      const req: API.Staff.CoWorking.Room.GetAll.Request = {
        limit,
        page,
        search,
        type,
      };
      return http.get<API.Staff.CoWorking.Room.GetAll.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Room.subPath}${API.Staff.CoWorking.Room.GetAll.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async get_by_id(props: API.Staff.CoWorking.Room.GetById.Request) {
      const { id } = props;
      const req: API.Staff.CoWorking.Room.GetById.Request = {
        id,
      };
      return http.get<API.Staff.CoWorking.Room.GetById.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Room.subPath}${API.Staff.CoWorking.Room.GetById.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async edit(props: API.Staff.CoWorking.Room.Edit.Request) {
      const { id, type, capacity, name, prices } = props;
      const req: API.Staff.CoWorking.Room.Edit.Request = {
        id,
        type,
        capacity,
        name,
        prices,
      };
      return http.put<API.Staff.CoWorking.Room.Edit.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Room.subPath}${API.Staff.CoWorking.Room.Edit.subPath}`,
        req,
        { withCredentials: true },
      );
    },
    async get_reserved_time_slot(props: API.Staff.CoWorking.Room.GetReservedTimeSlot.Request) {
      const { roomId, date } = props;
      const req: API.Staff.CoWorking.Room.GetReservedTimeSlot.Request = {
        roomId,
        date,
      };
      return http.get<API.Staff.CoWorking.Room.GetReservedTimeSlot.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Room.subPath}${API.Staff.CoWorking.Room.GetReservedTimeSlot.subPath}`,
        { params: req, withCredentials: true },
      );
    },
  },
  Booking: {
    async get_all(props: API.Staff.CoWorking.Booking.GetAll.Request) {
      const { limit, page, month, year, bookingDate, roomId, search } = props;
      const req: API.Staff.CoWorking.Booking.GetAll.Request = {
        limit,
        page,
        month,
        year,
        bookingDate,
        roomId,
        search,
      };
      return http.get<API.Staff.CoWorking.Booking.GetAll.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Booking.subPath}${API.Staff.CoWorking.Booking.GetAll.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async get_by_id(props: API.Staff.CoWorking.Booking.GetById.Request) {
      const { id } = props;
      const req: API.Staff.CoWorking.Booking.GetById.Request = {
        id,
      };
      return http.get<API.Staff.CoWorking.Booking.GetById.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Booking.subPath}${API.Staff.CoWorking.Booking.GetById.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async create(props: API.Staff.CoWorking.Booking.Create.Request) {
      const { userId, roomId, timeSlot, bookingDate, code, paymentMethod } = props;
      const req: API.Staff.CoWorking.Booking.Create.Request = {
        userId,
        roomId,
        timeSlot,
        bookingDate,
        code,
        paymentMethod,
      };
      return http.post<API.Staff.CoWorking.Booking.Create.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Booking.subPath}${API.Staff.CoWorking.Booking.Create.subPath}`,
        req,
        { withCredentials: true },
      );
    },
    async set_status(props: API.Staff.CoWorking.Booking.SetStatus.Request) {
      const { id, status } = props;
      const req: API.Staff.CoWorking.Booking.SetStatus.Request = {
        id,
        status,
      };
      return http.put<API.Staff.CoWorking.Booking.SetStatus.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Booking.subPath}${API.Staff.CoWorking.Booking.SetStatus.subPath}`,
        req,
        { withCredentials: true },
      );
    },
    async confirm_booking(props: API.Staff.CoWorking.Booking.ConfirmBooking.Request) {
      const { bookingId } = props;
      const req: API.Staff.CoWorking.Booking.ConfirmBooking.Request = {
        bookingId,
      };
      return http.put<API.Staff.CoWorking.Booking.ConfirmBooking.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Booking.subPath}${API.Staff.CoWorking.Booking.ConfirmBooking.subPath}`,
        req,
        { withCredentials: true },
      );
    },
  },
  Bill: {
    async get_all(props: API.Staff.CoWorking.Bill.GetAll.Request) {
      const { limit, page, search, startDate } = props;
      const req: API.Staff.CoWorking.Bill.GetAll.Request = {
        limit,
        page,
        search,
        startDate,
      };
      return http.get<API.Staff.CoWorking.Bill.GetAll.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Bill.subPath}${API.Staff.CoWorking.Bill.GetAll.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async get_by_booking_id(props: API.Staff.CoWorking.Bill.GetByBookingId.Request) {
      const { bookingId } = props;
      const req: API.Staff.CoWorking.Bill.GetByBookingId.Request = {
        bookingId,
      };
      return http.get<API.Staff.CoWorking.Bill.GetByBookingId.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Bill.subPath}${API.Staff.CoWorking.Bill.GetByBookingId.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async calculate_room_price(props: API.Staff.CoWorking.Bill.CalculateRoomPrice.Request) {
      const { timeSlot, prices, type, discountCode } = props;
      const req: API.Staff.CoWorking.Bill.CalculateRoomPrice.Request = {
        timeSlot,
        prices,
        type,
        discountCode,
      };
      return http.post<API.Staff.CoWorking.Bill.CalculateRoomPrice.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Bill.subPath}${API.Staff.CoWorking.Bill.CalculateRoomPrice.subPath}`,
        req,
        { withCredentials: true },
      );
    },
    async create(props: API.Staff.CoWorking.Bill.Create.Request) {
      const { userId, expiryDate, purchasedProducts, method, paid, code, type } = props;
      const req: API.Staff.CoWorking.Bill.Create.Request = {
        userId,
        expiryDate,
        purchasedProducts,
        method,
        paid,
        code,
        type,
      };
      return http.post<API.Staff.CoWorking.Bill.Create.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Bill.subPath}${API.Staff.CoWorking.Bill.Create.subPath}`,
        req,
        { withCredentials: true },
      );
    },
    async get_transaction_img_url(props: API.Staff.CoWorking.Bill.GetTransactionImgUrl.Request) {
      const { imgPath } = props;
      const req: API.Staff.CoWorking.Bill.GetTransactionImgUrl.Request = {
        imgPath,
      };
      return http.get<API.Staff.CoWorking.Bill.GetTransactionImgUrl.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Bill.subPath}${API.Staff.CoWorking.Bill.GetTransactionImgUrl.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async set_status(props: API.Staff.CoWorking.Bill.SetStatus.Request) {
      const { billId, status } = props;
      const req: API.Staff.CoWorking.Bill.SetStatus.Request = {
        billId,
        status,
      };
      return http.put<API.Staff.CoWorking.Bill.Create.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Bill.subPath}${API.Staff.CoWorking.Bill.SetStatus.subPath}`,
        req,
        { withCredentials: true },
      );
    },
  },
  Discount: {
    async get_all(props: API.Staff.CoWorking.Discount.GetAll.Request) {
      const { limit, page, search } = props;
      const req: API.Staff.CoWorking.Discount.GetAll.Request = {
        limit,
        page,
        search,
      };
      return http.get<API.Staff.CoWorking.Discount.GetAll.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Discount.subPath}${API.Staff.CoWorking.Discount.GetAll.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async get_one(props: API.Staff.CoWorking.Discount.GetOne.Request) {
      const { id } = props;
      const req: API.Staff.CoWorking.Discount.GetOne.Request = {
        id,
      };
      return http.get<API.Staff.CoWorking.Discount.GetOne.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Discount.subPath}${API.Staff.CoWorking.Discount.GetOne.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async add(props: API.Staff.CoWorking.Discount.Add.Request) {
      const { name, ...rest } = props;
      const req: API.Staff.CoWorking.Discount.Add.Request = {
        name,
        ...rest,
      };
      return http.post<API.Staff.CoWorking.Discount.Add.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Discount.subPath}${API.Staff.CoWorking.Discount.Add.subPath}`,
        req,
        { withCredentials: true },
      );
    },
    async delete(props: API.Staff.CoWorking.Discount.Remove.Request) {
      const { id } = props;
      const req: API.Staff.CoWorking.Discount.Remove.Request = {
        id,
      };
      return http.delete<API.Staff.CoWorking.Discount.Remove.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Discount.subPath}${API.Staff.CoWorking.Discount.Remove.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async edit(props: API.Staff.CoWorking.Discount.Edit.Request) {
      const { id, ...rest } = props;
      const req: API.Staff.CoWorking.Product.Edit.Request = {
        id,
        ...rest,
      };
      return http.put<API.Staff.CoWorking.Discount.Edit.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Discount.subPath}${API.Staff.CoWorking.Discount.Edit.subPath}`,
        req,
        { withCredentials: true },
      );
    },
    async try_apply(props: API.Staff.CoWorking.Discount.TryApply.Request) {
      const { code, items } = props;
      const req: API.Staff.CoWorking.Discount.TryApply.Request = {
        code,
        items,
      };
      return http.post<API.Staff.CoWorking.Discount.TryApply.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Discount.subPath}${API.Staff.CoWorking.Discount.TryApply.subPath}`,
        req,
        { withCredentials: true },
      );
    },
  },
  Product: {
    async get_all(props: API.Staff.CoWorking.Product.GetAll.Request) {
      const { limit, page, search } = props;
      const req: API.Staff.CoWorking.Product.GetAll.Request = {
        limit,
        page,
        search,
      };
      return http.get<API.Staff.CoWorking.Product.GetAll.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Product.subPath}${API.Staff.CoWorking.Product.GetAll.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async add(props: API.Staff.CoWorking.Product.AddOne.Request) {
      const { name, price } = props;
      const req: API.Staff.CoWorking.Product.AddOne.Request = {
        name,
        price,
      };
      return http.post<API.Staff.CoWorking.Discount.Add.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Product.subPath}${API.Staff.CoWorking.Product.AddOne.subPath}`,
        req,
        { withCredentials: true },
      );
    },
    async delete(props: API.Staff.CoWorking.Product.Remove.Request) {
      const { id } = props;
      const req: API.Staff.CoWorking.Product.Remove.Request = {
        id,
      };
      return http.delete<API.Staff.CoWorking.Product.Remove.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Product.subPath}${API.Staff.CoWorking.Product.Remove.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async edit(props: API.Staff.CoWorking.Product.Edit.Request) {
      const { id, ...rest } = props;
      const req: API.Staff.CoWorking.Product.Edit.Request = {
        id,
        ...rest,
      };
      return http.put<API.Staff.CoWorking.Product.Edit.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Product.subPath}${API.Staff.CoWorking.Product.Edit.subPath}`,
        req,
        { withCredentials: true },
      );
    },
  },
  Notification: {
    async get_all(props: API.Staff.CoWorking.Notification.GetAll.Request) {
      const req: API.Staff.CoWorking.Notification.GetAll.Request = props;
      return http.get<API.Staff.CoWorking.Notification.GetAll.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Notification.subPath}${API.Staff.CoWorking.Notification.GetAll.subPath}`,
        { params: req, withCredentials: true },
      );
    },
  },
  Member: {
    async get_all(props: API.Staff.CoWorking.Member.GetAllMember.Request) {
      const { limit, page, search, active } = props;
      const req: API.Staff.CoWorking.Member.GetAllMember.Request = {
        limit,
        page,
        search,
        active,
      };
      return http.get<API.Staff.CoWorking.Member.GetAllMember.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Member.subPath}${API.Staff.CoWorking.Member.GetAllMember.subPath}`,
        { params: req, withCredentials: true },
      );
    },
    async edit(props: API.Staff.CoWorking.Member.EditMember.Request) {
      const { id, ...rest } = props;
      const req: API.Staff.CoWorking.Member.EditMember.Request = {
        id,
        ...rest,
      };
      return http.put<API.Staff.CoWorking.Member.EditMember.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Member.subPath}${API.Staff.CoWorking.Member.EditMember.subPath}`,
        req,
        { withCredentials: true },
      );
    },
    async set_membership(props: API.Staff.CoWorking.Member.SetMembership.Request) {
      const { id, memberExpiryDate } = props;
      const req: API.Staff.CoWorking.Member.SetMembership.Request = {
        id,
        memberExpiryDate,
      };
      return http.put<API.Staff.CoWorking.Member.SetMembership.Response>(
        `${API.Staff.subRoute}${API.Staff.CoWorking.subPath}${API.Staff.CoWorking.Member.subPath}${API.Staff.CoWorking.Member.SetMembership.subPath}`,
        req,
        { withCredentials: true },
      );
    },
  },
};
