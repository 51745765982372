import { useEffect } from "react";

const Modal = (props: {
  title: string;
  children: JSX.Element;
  footer?: JSX.Element;
  footerAlign?: "center" | "end";
  close: () => void;
}) => {
  const { title, children, footer, close, footerAlign } = props;

  useEffect(() => {
    document.body.classList.add("custom-modal-open");

    return () => {
      document.body.classList.remove("custom-modal-open");
    };
  }, []);

  return (
    <div className="modal fade d-block show bg-modal">
      <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header justify-content-between row m-0">
            <div className="col"></div>
            <div className="modal-title fw-bold fs-4 col text-center">{title}</div>
            <div className="col text-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={close}
              ></button>
            </div>
          </div>
          <div className="modal-body">{children}</div>
          <div className={`modal-footer ${footerAlign ? `justify-content-${footerAlign}` : ""}`}>
            {footer}
          </div>
        </div>
      </div>
    </div>
  );
};

export { Modal };
