import * as API from "../api";
import * as Types from "../main";

export const subPath = "/user";

/**
 * Get own
 *
 * - path: /user/get-own
 * - method: "GET"
 * @ for registered user.
 */
export namespace GetOwn {
  export const method: API.RequestMethod = "get";
  export const subPath = "/get-own";
  /** querystring */
  export type Request = {
    /**/
  };
  export type Response = API.ResponsePayload<CustomType.Info>;
}

/**
 * Request reset password
 *
 * - path: /user/reset-password
 * - method: "POST"
 * @ for all registered user.
 */
export namespace ResetPassword {
  export const method: API.RequestMethod = "post";
  export const subPath = "/reset-password";
  /** body */
  export type Request = {
    email: string;
  };
  export type Response = API.ResponsePayload<null, "reset password mail was sent to user's email.">;
}

/**
 * Login and get authentication.
 *
 * - note:
 *   - min username length: 6
 *   - min password length: 6
 * - path: /user/login
 * - method: "POST"
 * @ for all.
 */
export namespace Login {
  export const method: API.RequestMethod = "post";
  export const subPath = "/login";
  /** body */
  export type Request = {
    username: string;
    password: string;
    /** to verify a staff authentication. */
    staff?: boolean;
  };
  export type Response = API.ResponsePayload<null, "login successfully">;
}

/**
 * Refresh authentication token.
 *
 * - path: /user/refresh
 * - method: "POST"
 * @ for all.
 */
export namespace Refresh {
  export const method: API.RequestMethod = "post";
  export const subPath = "/refresh";
  /** body */
  export type Request = null;
  export type Response = API.ResponsePayload<
    null,
    "refresh token successfully" | "token is already up to date"
  >;
}

/**
 * Logout and revoke authentication.
 *
 * - path: /user/logout
 * - method: "POST"
 * @ for all.
 */
export namespace Logout {
  export const method: API.RequestMethod = "post";
  export const subPath = "/logout";
  /** body */
  export type Request = null;
  export type Response = API.ResponsePayload<null, "logout successfully">;
}

export namespace Register {
  export type ErrorMessage =
    | API.DefaultErrorMessage
    | `duplicated ${"username" | "email" | "phoneNumber"}`;

  /**
   * Register a new customer.
   *
   * - path: /user/register-customer
   * - method: "POST"
   * @ for all
   */
  export namespace Customer {
    export const method: API.RequestMethod = "post";
    export const subPath = "/register-customer";
    /** body */
    export type Request = Pick<
      Types.User.Type,
      | "firstName"
      | "lastName"
      | "username"
      | "password"
      | "email"
      | "lineId"
      | "phoneNumber"
      | "birthDate"
    >;
    export type Response = API.ResponsePayload<
      null,
      | "registered a new customer user successfully"
      | {
          error: ErrorMessage;
        }
    >;
  }
}

export namespace Edit {
  export type DefaultData = Partial<
    Pick<
      Types.User.Type,
      "firstName" | "lastName" | "email" | "lineId" | "phoneNumber" | "birthDate"
    >
  >;

  /**
   * Edit own user info
   *
   * - path: /user/edit/own-info
   * - method: "PUT"
   * @ for registered user.
   */
  export namespace Own {
    export const method: API.RequestMethod = "put";
    export const subPath = "/edit-own";
    /** body */
    export type Request = DefaultData & {
      password?: string;
    };
    export type Response = API.ResponsePayload<null, "edit user info successfully">;
  }
}

export namespace CustomType {
  /**
   * basic user info.
   */
  export type Basic = Pick<
    Types.User.Type,
    | "id"
    | "firstName"
    | "lastName"
    | "phoneNumber"
    | "email"
    | "lineId"
    | "role"
    | "department"
    | "username"
    | "active"
    | "birthDate"
    | "memberExpiryDate"
  >;

  /**
   * general info (ex. removed password)
   *
   */
  export type Info = Omit<Types.User.Type, "password"> & {
    //
  };
}
