import { API, Types } from "shared-lib";

type Renter = API.User.CustomType.Basic | Types.User.Type;

export const initialRenter: Renter = {
  id: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  lineId: "",
  role: "customer",
  department: "customer",
  username: "",
  active: false,
  birthDate: new Date().getTime(),
  memberExpiryDate: 0,
};
