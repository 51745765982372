import addDays from "date-fns/addDays";
import format from "date-fns/format";
import TH from "date-fns/locale/th";
// import { DaysOfWeek } from "../types/main/Discount";
import { Types } from "../";

const DAY_STRING = "days";

export const SECOND = 1_000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;

export const getDaysString = (days: number, sep?: string) => {
  return `${days}${sep || ""}${DAY_STRING}`;
};

const createFormatDate = () => {
  return (date: Date | number, dateFormat: string) => {
    return format(date, dateFormat, {
      locale: TH,
    });
  };
};

export const getCurrentTime = () => {
  return new Date().getTime();
};

const formatDate = createFormatDate();

export const removeDays = (days: number) => {
  return addDays(new Date(), -days);
};

export const getLocaleDateString = (date: number | Date) => {
  return formatDate(date, "dd MMMM yyyy");
};

export const getLocaleTimeString = (date: number | Date) => {
  return formatDate(date, "HH:mm");
};

export const getLocaleMonthString = (date: number | Date) => {
  return formatDate(date, "MMMM");
};

export const getLocaleYearString = (date: number | Date) => {
  return formatDate(date, "yyyy");
};

/* get hour and minute in time (ms) */
export const getHourMinuteTime = (hour: number, minute: number): number => {
  const date = new Date(0);
  date.setHours(hour, minute);
  return date.getTime();
};

export const setHourMinuteTime = (date: Date, hour: number, minute: number): number => {
  date.setHours(hour, minute);
  return date.getTime();
};

export const setPaidHourMinuteTime = (date: Date, hour: number, minute: number): number => {
  date.setHours(hour, minute, 0, 0);
  return date.getTime();
};

export const setMillisecond = (date: Date, ms: number): number => {
  date.setTime(ms);
  return date.getTime();
};

export const getMonthYear = (now: Date): Types.MonthYear => {
  return {
    month: now.getMonth() as 0,
    year: now.getFullYear(),
  };
};

export const compareMonthYear = (a: Types.MonthYear, b: Types.MonthYear) => {
  return a.year === b.year && a.month === b.month;
};

export const getDateObj = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const milliseconds = date.getMilliseconds();

  return {
    day,
    month,
    year,
    hours,
    minutes,
    seconds,
    milliseconds,
  };
};

export const getHoursObj = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const milliseconds = date.getMilliseconds();

  return {
    hours,
    minutes,
    seconds,
    milliseconds,
  };
};

export const getTimeZoneOffset = () => {
  return new Date().getTimezoneOffset() * MINUTE;
};

// export const setH
