import { useState } from "react";

const Pagination = (props: {
  page: number;
  setPage: (value: number) => void;
  isLastPage?: boolean;
  position?: string;
}) => {
  const { page, setPage, isLastPage, position } = props;
  const [previousDisable, setPreviousDisable] = useState(false);
  const [nextDisable, setNextDisable] = useState(false);

  return (
    <ul className={`pagination justify-content-${position ? position : "end"}`}>
      <li className={`page-item ${previousDisable ? "disable" : ""}`}>
        <a
          className="page-link"
          href="#"
          aria-label="Previous"
          onClick={() => {
            if (page === 0) {
              setPreviousDisable(true);
            } else {
              setPage(page - 1);
            }
          }}
        >
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <div className="align-items-center p-2">{page + 1}</div>
      <li className={`page-item ${nextDisable ? "disable" : ""}`}>
        <a
          className="page-link"
          href="#"
          aria-label="Next"
          onClick={() => {
            if (isLastPage) {
              setNextDisable(true);
            } else {
              setPage(page + 1);
            }
          }}
        >
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  );
};

export { Pagination };
