import { Types } from "shared-lib/src/types";
import { TimeSlot } from "shared-lib/src/types/main";
import { HOUR, MINUTE } from "shared-lib/src/utility/time";

export const convertDepartmentType = (department: Types.Department.Def) => {
  switch (department) {
    case "apartment":
      return "อพาร์ทเมนต์";
    case "co-working":
      return "Co-Working";
  }
};

export const convertPaymentMethod = (payment: Types.Bill.PaymentMethod.Def) => {
  switch (payment) {
    case "cash":
      return "เงินสด";
    case "qr":
      return "QR CODE";
  }
};

export const convertBookingStatus = (booking: Types.BookingStatus.Def) => {
  switch (booking) {
    case "checked-out":
      return "checked-out";
    case "canceled":
      return "ยกเลิก";
    case "waiting":
      return "กำลังรอการยืนยัน";
    case "confirmed":
      return "จองแล้ว";
    case "checked-in":
      return "checked-in";
  }
};

export const convertBillStatus = (bill: Types.Bill.Status.Def) => {
  switch (bill) {
    case "unpaid":
      return "ยังไม่ชำระ";
    case "paid":
      return "ชำระแล้ว";
    case "expired":
      return "หมดอายุ";
  }
};

export type TimeToHourType = {
  startHourString: string;
  endHourString: string;
};

export const convertTimeToHourString = (timeSlot: TimeSlot): TimeToHourType => {
  const { startTime, endTime } = timeSlot;
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);
  const startHours = startDate.getHours();
  const startMinutes = startDate.getMinutes();
  const endHours = endDate.getHours();
  const endMinutes = endDate.getMinutes();

  return {
    startHourString: `${startHours}:${startMinutes}${startMinutes > 0 ? "" : "0"}`,
    endHourString: `${endHours}:${endMinutes}${endMinutes > 0 ? "" : "0"}`,
  };
};

export const convertBookingTimeSlot = (props: { timeSlot: TimeSlot[] }) => {
  const { timeSlot } = props;
  const currentTimeSlot = timeSlot.sort((a, b) => {
    return a.startTime - b.startTime;
  });
  const result: string[] = [];
  let currentIdx = 0;
  let currentSlot: TimeSlot = { startTime: 0, endTime: 0 };
  currentTimeSlot.forEach((slot) => {
    const { startTime, endTime } = slot;

    if (currentIdx === 0) {
      currentSlot = { startTime: startTime, endTime: endTime };
    }
    if (currentIdx !== 0 && currentIdx + 1 < timeSlot.length) {
      if (currentSlot.endTime === startTime) {
        currentSlot.endTime = endTime;
      } else {
        const { startHourString, endHourString } = convertTimeToHourString(currentSlot);
        result.push(`${startHourString} - ${endHourString}`);
        currentSlot = { startTime: startTime, endTime: endTime };
      }
    }
    if (currentIdx !== 0 && currentIdx + 1 === timeSlot.length) {
      if (currentSlot.endTime === startTime) {
        currentSlot.endTime = endTime;
        const { startHourString, endHourString } = convertTimeToHourString(currentSlot);
        result.push(`${startHourString} - ${endHourString}`);
      } else {
        const { startHourString: currentStartSlot, endHourString: currentEndSlot } =
          convertTimeToHourString(currentSlot);
        result.push(`${currentStartSlot} - ${currentEndSlot}`);
        const { startHourString, endHourString } = convertTimeToHourString(slot);
        result.push(`${startHourString} - ${endHourString}`);
      }
    }
    currentIdx = currentIdx + 1;
  });
  return result;
};
