import { useEffect, useState, useRef } from "react";
// import type { ApartmentRoomRentType, DaysOfWeek } from "shared-lib/src/types/old/dev";
// import { DiscountType } from "shared-lib/src/types/old/dev";
import { Checkbox } from "./Checkbox";
// import type { UserStatus } from "../Apartment/Types2";
import * as Types from "shared-lib/src/types/main";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { AdminAPI } from "../Apartment/api/service";
import { Pagination } from "./Pagination";
import { ApartmentRoom, ApartmentRoomRentType } from "../Apartment/types/room";
import { DiscountType } from "../Apartment/types/discount";
import { Room, RoomType } from "../Co-Working/types/room";
import { convertCoWorkingRoomType } from "../Apartment/functions/convert-types";
import { CoWorkingAPI } from "../Co-Working/api/service";

const DropdownList = (props: {
  selected: string;
  setSelected: (value: string) => void;
  data: string[];
}) => {
  const { selected, setSelected, data } = props;
  const [isActive, setIsActive] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div className="dropdown">
      <div className="w-80">
        <button
          className={`btn dropdown-toggle w-100 ${showDropdown === false ? "" : "show"}`}
          id="dropdown-basic-button"
          type="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={showDropdown}
          onClick={() => {
            if (isActive === true) {
              setIsActive(false);
              setShowDropdown(false);
            } else {
              setIsActive(true);
              setShowDropdown(true);
            }
          }}
        >
          {selected}
        </button>
        {isActive === true ? (
          <div className="d-flex justify-content-center text-center">
            <ul className={`dropdown-menu w-50 shadow-lg ${showDropdown === false ? "" : "show"}`}>
              {data.map((data, index) => (
                <li key={index}>
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      setSelected(data);
                      setIsActive(false);
                      setShowDropdown(false);
                    }}
                  >
                    {data}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <ul className={"dropdown-menu"} aria-labelledby="dropdown-basic-button">
            {data.map((data, index) => (
              <li key={index}>
                <button
                  className="dropdown-item"
                  onClick={() => {
                    setSelected(data);
                    setIsActive(false);
                    setShowDropdown(false);
                  }}
                >
                  {data}
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

const MonthYearDropdownList = (props: {
  selected: Types.MonthYear;
  setSelected: (value: Types.MonthYear) => void;
  data: Types.MonthYear[];
}) => {
  const { selected, setSelected, data } = props;
  const [isActive, setIsActive] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const target = event.target as HTMLElement;
      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setIsActive(false);
        setShowDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div className="w-80">
        <button
          className={`btn dropdown-toggle w-100 ${showDropdown === false ? "" : "show"}`}
          id="dropdown-basic-button"
          type="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={showDropdown}
          onClick={() => {
            if (isActive === true) {
              setIsActive(false);
              setShowDropdown(false);
            } else {
              setIsActive(true);
              setShowDropdown(true);
            }
          }}
        >
          {dayjs().month(selected.month).format("MMMM") +
            " " +
            dayjs().year(selected.year).format("YYYY")}
        </button>
        {isActive === true ? (
          <div className="d-flex justify-content-center text-center">
            <ul
              className={`dropdown-menu custom-dropdown w-50 shadow-lg ${
                showDropdown === false ? "" : "show"
              }`}
            >
              {data.map((data, index) => (
                <li key={index}>
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      setSelected(data);
                      setIsActive(false);
                      setShowDropdown(false);
                    }}
                  >
                    {dayjs().month(data.month).format("MMMM") +
                      " " +
                      dayjs().year(data.year).format("YYYY")}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const CoWorkingRoomDropdownList = (props: {
  selected: RoomType;
  setSelected: (value: RoomType) => void;
  data: RoomType[];
}) => {
  const { selected, setSelected, data } = props;
  const [isActive, setIsActive] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const target = event.target as HTMLElement;
      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setIsActive(false);
        setShowDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div className="w-80">
        <button
          className={`btn dropdown-toggle w-100 ${showDropdown === false ? "" : "show"}`}
          id="dropdown-basic-button"
          type="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={showDropdown}
          onClick={() => {
            if (isActive === true) {
              setIsActive(false);
              setShowDropdown(false);
            } else {
              setIsActive(true);
              setShowDropdown(true);
            }
          }}
        >
          {convertCoWorkingRoomType(selected)}
        </button>
        {isActive === true ? (
          <div className="d-flex justify-content-center text-center">
            <ul
              className={`dropdown-menu custom-dropdown w-50 shadow-lg ${
                showDropdown === false ? "" : "show"
              }`}
            >
              {data.map((data, index) => (
                <li key={index}>
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      setSelected(data);
                      setIsActive(false);
                      setShowDropdown(false);
                    }}
                  >
                    {convertCoWorkingRoomType(data)}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const CoWorkingRoomNameDropdownList = (props: { setSelected: (value: string) => void }) => {
  const { setSelected } = props;
  const [isActive, setIsActive] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [roomData, setRoomData] = useState<Room[]>([]);
  const [showSelected, setShowSelected] = useState("");

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const target = event.target as HTMLElement;
      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setIsActive(false);
        setShowDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const roomResponse = await CoWorkingAPI.Room.get_all({ limit: 10, page: 0 });
        if (roomResponse.data.ok) {
          const rooms = roomResponse.data.data;
          setRoomData(rooms);
          setShowSelected(rooms[0].name);
          setSelected(rooms[0].id);
        }
      } catch (e) {
        toast.error("เกิดปัญหาในการเรียกข้อมูลชื่อห้อง");
      }
    })();
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div className="w-80">
        <button
          className={`btn dropdown-toggle w-100 ${showDropdown === false ? "" : "show"}`}
          id="dropdown-basic-button"
          type="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={showDropdown}
          onClick={() => {
            if (isActive === true) {
              setIsActive(false);
              setShowDropdown(false);
            } else {
              setIsActive(true);
              setShowDropdown(true);
            }
          }}
        >
          {showSelected}
        </button>
        {isActive === true ? (
          <>
            <div className="d-flex justify-content-center text-center">
              <ul
                className={`dropdown-menu dropdown-menu-product custom-dropdown shadow-lg ${
                  showDropdown === false ? "" : "show"
                }`}
              >
                <>
                  {roomData.map((data, index) => (
                    <li key={index}>
                      <button
                        className="dropdown-item"
                        onClick={() => {
                          setSelected(data.id);
                          setShowSelected(data.name);
                          setIsActive(false);
                          setShowDropdown(false);
                        }}
                      >
                        <div className="text-center">{data.name}</div>
                      </button>
                    </li>
                  ))}
                </>
              </ul>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const PurchasedProductDropdownList = (props: {
  selected: Types.Product.Type;
  setSelected: (value: Types.Product.Type) => void;
}) => {
  const { selected, setSelected } = props;
  const [page, setPage] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const [productData, setProductData] = useState<Types.Product.Type[]>([]);
  const [isActive, setIsActive] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const target = event.target as HTMLElement;
      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setIsActive(false);
        setShowDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const responseProduct = await AdminAPI.Product.get_all({ limit: 5, page: page });
        if (responseProduct.data.ok) {
          const products = responseProduct.data.data;
          if (products.length < 5) {
            setProductData(products);
            setIsLastPage(true);
          } else {
            setProductData(responseProduct.data.data);
            setIsLastPage(false);
          }
        }
      } catch (e) {
        toast.error("เกิดปัญหาในการเรียกข้อมูลสินค้า");
      }
    })();
  }, [page]);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div className="w-80">
        <button
          className={`btn dropdown-toggle w-100 ${showDropdown === false ? "" : "show"}`}
          id="dropdown-basic-button"
          type="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={showDropdown}
          onClick={() => {
            if (isActive === true) {
              setIsActive(false);
              setShowDropdown(false);
            } else {
              setIsActive(true);
              setShowDropdown(true);
            }
          }}
        >
          {selected.name}
        </button>
        {isActive === true ? (
          <>
            <div className="d-flex justify-content-center text-center">
              <ul
                className={`dropdown-menu dropdown-menu-product custom-dropdown shadow-lg ${
                  showDropdown === false ? "" : "show"
                }`}
              >
                <>
                  {productData.map((data, index) => (
                    <li key={index}>
                      <button
                        className="dropdown-item"
                        onClick={() => {
                          setSelected(data);
                          setIsActive(false);
                          setShowDropdown(false);
                        }}
                      >
                        <div className="text-center">{data.name}</div>
                      </button>
                    </li>
                  ))}
                  <div className="mt-3" />
                  <Pagination
                    page={page}
                    setPage={setPage}
                    isLastPage={isLastPage}
                    position="center"
                  />
                </>
              </ul>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const RoomDropdownList = (props: {
  selected: ApartmentRoomRentType;
  setSelected: (value: ApartmentRoomRentType) => void;
  data: ApartmentRoomRentType[];
}) => {
  const { selected, setSelected, data } = props;
  const [isActive, setIsActive] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const target = event.target as HTMLElement;
      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setIsActive(false);
        setShowDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div className="w-80">
        <button
          className={`btn dropdown-toggle w-100 ${showDropdown === false ? "" : "show"}`}
          id="dropdown-basic-button"
          type="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={showDropdown}
          onClick={() => {
            if (isActive === true) {
              setIsActive(false);
              setShowDropdown(false);
            } else {
              setIsActive(true);
              setShowDropdown(true);
            }
          }}
        >
          {selected === "monthly" ? "ห้องพักรายเดือน" : "ห้องพักรายวัน"}
        </button>
        {isActive === true ? (
          <div className="d-flex justify-content-center text-center">
            <ul className={`dropdown-menu w-50 shadow-lg ${showDropdown === false ? "" : "show"}`}>
              {data.map((data, index) => (
                <li key={index}>
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      setSelected(data);
                      setIsActive(false);
                      setShowDropdown(false);
                    }}
                  >
                    {data === "monthly" ? "ห้องพักรายเดือน" : "ห้องพักรายวัน"}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

type RoomNoListData = Pick<Types.Apartment.Room.Type, "roomNo" | "id">;

const AvaialbeDailyRoomDropdownList = (props: {
  startDate: number;
  endDate: number;
  roomType: Types.Apartment.Room.RoomType.Def;
  setSelected: (value: string) => void;
}) => {
  const { startDate, endDate, roomType, setSelected } = props;
  const [listData, setListData] = useState<RoomNoListData[]>([]);
  const [isActive, setIsActive] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSelected, setShowSelected] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const listResponse = await AdminAPI.Room.get_available_room({
          startDate,
          endDate,
          roomType,
        });
        if (listResponse.data.ok) {
          const roomList = listResponse.data.data;
          setListData(roomList);
          setShowSelected(roomList[0].roomNo);
          setSelected(roomList[0].id);
        }
      } catch (e) {
        toast.error("มีปัญหาเกิดขึ้น");
      }
    })();
  }, [startDate, endDate, roomType, setSelected]);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const target = event.target as HTMLElement;
      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setIsActive(false);
        setShowDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div className="w-80">
        <button
          className={`btn dropdown-toggle w-100 ${showDropdown === false ? "" : "show"}`}
          id="dropdown-basic-button"
          type="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={showDropdown}
          onClick={() => {
            if (isActive === true) {
              setIsActive(false);
              setShowDropdown(false);
            } else {
              setIsActive(true);
              setShowDropdown(true);
            }
          }}
        >
          {showSelected}
        </button>
        {isActive === true ? (
          <div className="d-flex justify-content-center text-center">
            <ul className={`dropdown-menu w-50 shadow-lg ${showDropdown === false ? "" : "show"}`}>
              {typeof listData !== "undefined"
                ? listData.map((data, index) => (
                    <li key={index}>
                      <button
                        className="dropdown-item"
                        onClick={() => {
                          setSelected(data.id);
                          setShowSelected(data.roomNo);
                          setIsActive(false);
                          setShowDropdown(false);
                        }}
                      >
                        <div className="text-center">{data.roomNo}</div>
                      </button>
                    </li>
                  ))
                : ""}
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const RoomDailyDropdownList = (props: { setSelected: (value: string) => void }) => {
  const { setSelected } = props;
  const [page, setPage] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const [listData, setListData] = useState<ApartmentRoom[]>([]);
  const [isActive, setIsActive] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSelected, setShowSelected] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const listResponse = await AdminAPI.Room.get_all({ limit: 7, page: page, type: "daily" });
        if (listResponse.data.ok) {
          const roomList = listResponse.data.data;
          setListData(roomList);
          setShowSelected(roomList[0].roomNo);
          setSelected(roomList[0].id);
          if (roomList.length < 7) {
            setIsLastPage(true);
          } else {
            setIsLastPage(false);
          }
        }
      } catch (e) {
        toast.error("มีปัญหาเกิดขึ้น");
      }
    })();
  }, [page, setSelected]);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const target = event.target as HTMLElement;
      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setIsActive(false);
        setShowDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div className="w-80">
        <button
          className={`btn dropdown-toggle w-100 ${showDropdown === false ? "" : "show"}`}
          id="dropdown-basic-button"
          type="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={showDropdown}
          onClick={() => {
            if (isActive === true) {
              setIsActive(false);
              setShowDropdown(false);
            } else {
              setIsActive(true);
              setShowDropdown(true);
            }
          }}
        >
          {showSelected}
        </button>
        {isActive === true ? (
          <div className="d-flex justify-content-center text-center">
            <ul className={`dropdown-menu w-50 shadow-lg ${showDropdown === false ? "" : "show"}`}>
              {typeof listData !== "undefined"
                ? listData.map((data, index) => (
                    <li key={index}>
                      <button
                        className="dropdown-item"
                        onClick={() => {
                          setSelected(data.id);
                          setShowSelected(data.roomNo);
                          setIsActive(false);
                          setShowDropdown(false);
                        }}
                      >
                        <div className="text-center">{data.roomNo}</div>
                      </button>
                    </li>
                  ))
                : ""}
              <div className="mt-3" />
              <Pagination page={page} setPage={setPage} isLastPage={isLastPage} position="center" />
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const UserDropdownList = (props: {
  selected: boolean;
  setSelected: (value: boolean) => void;
  data: boolean[];
}) => {
  const { selected, setSelected, data } = props;
  const [isActive, setIsActive] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const target = event.target as HTMLElement;
      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setIsActive(false);
        setShowDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div className="w-80">
        <button
          className={`btn dropdown-toggle w-100 ${showDropdown === false ? "" : "show"}`}
          id="dropdown-basic-button"
          type="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={showDropdown}
          onClick={() => {
            if (isActive === true) {
              setIsActive(false);
              setShowDropdown(false);
            } else {
              setIsActive(true);
              setShowDropdown(true);
            }
          }}
        >
          {selected === true ? "สามารถใช้งานได้" : "ไม่สามารถใช้งานได้"}
        </button>
        {isActive === true ? (
          <div className="d-flex justify-content-center text-center">
            <ul className={`dropdown-menu w-50 shadow-lg ${showDropdown === false ? "" : "show"}`}>
              {data.map((data, index) => (
                <li key={index}>
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      setSelected(data);
                      setIsActive(false);
                      setShowDropdown(false);
                    }}
                  >
                    {data === true ? "สามารถใช้งานได้" : "ไม่สามารถใช้งานได้"}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const DiscountDropdownList = (props: {
  selected: DiscountType;
  setSelected: (value: DiscountType) => void;
  data: DiscountType[];
}) => {
  const { selected, setSelected, data } = props;
  const [isActive, setIsActive] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const target = event.target as HTMLElement;
      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setIsActive(false);
        setShowDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div className="w-80">
        <button
          className={`btn dropdown-toggle w-100 ${showDropdown === false ? "" : "show"}`}
          id="dropdown-basic-button"
          type="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={showDropdown}
          onClick={() => {
            if (isActive === true) {
              setIsActive(false);
              setShowDropdown(false);
            } else {
              setIsActive(true);
              setShowDropdown(true);
            }
          }}
        >
          {selected === "percent" ? "%" : "บาท"}
        </button>
        {isActive === true ? (
          <div className="d-flex justify-content-center text-center">
            <ul className={`dropdown-menu w-50 shadow-lg ${showDropdown === false ? "" : "show"}`}>
              {data.map((data, index) => (
                <li key={index}>
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      setSelected(data);
                      setIsActive(false);
                      setShowDropdown(false);
                    }}
                  >
                    {data === "percent" ? "%" : "บาท"}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const StaffDepartmentDropdownList = (props: {
  selected: Types.Department.User.Def;
  setSelected: (value: Types.Department.User.Def) => void;
  data: Types.Department.User.Def[];
}) => {
  const { selected, setSelected, data } = props;
  const [isActive, setIsActive] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const target = event.target as HTMLElement;
      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setIsActive(false);
        setShowDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div className="w-80">
        <button
          className={`btn dropdown-toggle w-100 ${showDropdown === false ? "" : "show"}`}
          id="dropdown-basic-button"
          type="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={showDropdown}
          onClick={() => {
            if (isActive === true) {
              setIsActive(false);
              setShowDropdown(false);
            } else {
              setIsActive(true);
              setShowDropdown(true);
            }
          }}
        >
          {selected}
        </button>
        {isActive === true ? (
          <div className="d-flex justify-content-center text-center">
            <ul className={`dropdown-menu w-50 shadow-lg ${showDropdown === false ? "" : "show"}`}>
              {data.map((data, index) => (
                <li key={index}>
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      setSelected(data);
                      setIsActive(false);
                      setShowDropdown(false);
                    }}
                  >
                    {data}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const ActiveMemberDropdownList = (props: {
  selected: boolean;
  setSelected: (value: boolean) => void;
  data: boolean[];
}) => {
  const { selected, setSelected, data } = props;
  const [isActive, setIsActive] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const target = event.target as HTMLElement;
      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setIsActive(false);
        setShowDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div className="w-80">
        <button
          className={`btn dropdown-toggle w-100 ${showDropdown === false ? "" : "show"}`}
          id="dropdown-basic-button"
          type="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={showDropdown}
          onClick={() => {
            if (isActive === true) {
              setIsActive(false);
              setShowDropdown(false);
            } else {
              setIsActive(true);
              setShowDropdown(true);
            }
          }}
        >
          {selected === true ? "สามารถใช้งานได้" : "ไม่สามารถใช้งานได้"}
        </button>
        {isActive === true ? (
          <div className="d-flex justify-content-center text-center">
            <ul className={`dropdown-menu w-50 shadow-lg ${showDropdown === false ? "" : "show"}`}>
              {data.map((data, index) => (
                <li key={index}>
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      setSelected(data);
                      setIsActive(false);
                      setShowDropdown(false);
                    }}
                  >
                    {data === true ? "สามารถใช้งานได้" : "ไม่สามารถใช้งานได้"}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const ActiveStaffDropdownList = (props: {
  selected: boolean;
  setSelected: (value: boolean) => void;
  data: boolean[];
}) => {
  const { selected, setSelected, data } = props;
  const [isActive, setIsActive] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const target = event.target as HTMLElement;
      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setIsActive(false);
        setShowDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div className="w-80">
        <button
          className={`btn dropdown-toggle w-100 ${showDropdown === false ? "" : "show"}`}
          id="dropdown-basic-button"
          type="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={showDropdown}
          onClick={() => {
            if (isActive === true) {
              setIsActive(false);
              setShowDropdown(false);
            } else {
              setIsActive(true);
              setShowDropdown(true);
            }
          }}
        >
          {selected === true ? "สามารถใช้งานได้" : "ไม่สามารถใช้งานได้"}
        </button>
        {isActive === true ? (
          <div className="d-flex justify-content-center text-center">
            <ul className={`dropdown-menu w-50 shadow-lg ${showDropdown === false ? "" : "show"}`}>
              {data.map((data, index) => (
                <li key={index}>
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      setSelected(data);
                      setIsActive(false);
                      setShowDropdown(false);
                    }}
                  >
                    {data === true ? "สามารถใช้งานได้" : "ไม่สามารถใช้งานได้"}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const ActiveDiscountDropdownList = (props: {
  selected: boolean;
  setSelected: (value: boolean) => void;
  data: boolean[];
}) => {
  const { selected, setSelected, data } = props;
  const [isActive, setIsActive] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const target = event.target as HTMLElement;
      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setIsActive(false);
        setShowDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div className="w-80">
        <button
          className={`btn dropdown-toggle w-100 ${showDropdown === false ? "" : "show"}`}
          id="dropdown-basic-button"
          type="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={showDropdown}
          onClick={() => {
            if (isActive === true) {
              setIsActive(false);
              setShowDropdown(false);
            } else {
              setIsActive(true);
              setShowDropdown(true);
            }
          }}
        >
          {selected === true ? "พร้อมใช้งาน" : "ไม่พร้อมใช้งาน"}
        </button>
        {isActive === true ? (
          <div className="d-flex justify-content-center text-center">
            <ul className={`dropdown-menu w-50 shadow-lg ${showDropdown === false ? "" : "show"}`}>
              {data.map((data, index) => (
                <li key={index}>
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      setSelected(data);
                      setIsActive(false);
                      setShowDropdown(false);
                    }}
                  >
                    {data === true ? "พร้อมใช้งาน" : "ไม่พร้อมใช้งาน"}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const PaymentMethodDropdownList = (props: {
  selected: Types.Bill.PaymentMethod.Def;
  setSelected: (value: Types.Bill.PaymentMethod.Def) => void;
  data: Types.Bill.PaymentMethod.Def[];
  roomCost?: boolean;
}) => {
  const { selected, setSelected, data, roomCost } = props;
  const [isActive, setIsActive] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const target = event.target as HTMLElement;
      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setIsActive(false);
        setShowDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className={`${roomCost ? "room-cost-dropdown" : "dropdown"}`} ref={dropdownRef}>
      <div className="w-80">
        <button
          className={`btn dropdown-toggle w-100 ${showDropdown === false ? "" : "show"}`}
          id="dropdown-basic-button"
          type="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={showDropdown}
          onClick={() => {
            if (isActive === true) {
              setIsActive(false);
              setShowDropdown(false);
            } else {
              setIsActive(true);
              setShowDropdown(true);
            }
          }}
        >
          {selected === "qr" ? "QR CODE" : "เงินสด"}
        </button>
        {isActive === true ? (
          <div className="d-flex justify-content-center text-center">
            <ul
              className={`dropdown-menu ${roomCost ? "w-80" : "w-50"} shadow-lg ${
                showDropdown === false ? "" : "show"
              }`}
            >
              {data.map((data, index) => (
                <li key={index}>
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      setSelected(data);
                      setIsActive(false);
                      setShowDropdown(false);
                    }}
                  >
                    <div className="text-center">{data === "qr" ? "QR CODE" : "เงินสด"}</div>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const DayDropdownList = (props: {
  selected: Types.Discount.DaysOfWeek[];
  setSelected: (value: Types.Discount.DaysOfWeek[]) => void;
  data: Types.Discount.DaysOfWeek[];
  disabled?: boolean;
}) => {
  const { selected, setSelected, data, disabled } = props;
  const [isActive, setIsActive] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const target = event.target as HTMLElement;
      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setIsActive(false);
        setShowDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const firstDayList: string[] = [];
  const lastDayList: string[] = [];
  const selectDays: string[] = [];
  data.sort((a, b) => a - b);
  data.forEach((day) => {
    switch (day) {
      case 0:
        firstDayList.push("อาทิตย์");
        break;
      case 1:
        firstDayList.push("จันทร์");
        break;
      case 2:
        firstDayList.push("อังคาร");
        break;
      case 3:
        firstDayList.push("พุธ");
        break;
      case 4:
        lastDayList.push("พฤหัสบดี");
        break;
      case 5:
        lastDayList.push("ศุกร์");
        break;
      case 6:
        lastDayList.push("เสาร์");
        break;
    }
  });
  lastDayList.push("ทุกวัน");

  if (selected) {
    if (selected.length === 7) {
      selectDays.push("ทุกวัน");
    } else {
      selected.forEach((select) => {
        switch (select) {
          case 0:
            selectDays.push("อา.");
            break;
          case 1:
            selectDays.push("จ.");
            break;
          case 2:
            selectDays.push("อ.");
            break;
          case 3:
            selectDays.push("พ.");
            break;
          case 4:
            selectDays.push("พฤ.");
            break;
          case 5:
            selectDays.push("ศ.");
            break;
          case 6:
            selectDays.push("ส.");
            break;
        }
      });
    }
  }

  const [selectedDayList, setSelectedDaylist] = useState<string[]>(selectDays);
  const dayList = ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส.", "ทุกวัน"];
  const [checkedState, setCheckedState] = useState(new Array(dayList.length).fill(false));
  const updateSelectedDayList: string[] = [];
  const newSelectedDayList: Types.Discount.DaysOfWeek[] = [];

  const handleOnChange = (position: number) => {
    let updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item,
    );
    let checkCount = 0;
    let allCheck = false;
    updatedCheckedState.forEach((check, index) => {
      if (check === true && index !== 7) {
        checkCount++;
      }
    });
    if (checkCount === 7) {
      allCheck = true;
    } else {
      allCheck = false;
    }

    if (position !== 7) {
      if (allCheck) {
        updatedCheckedState = checkedState.map(() => {
          return true;
        });
        setSelectedDaylist(["ทุกวัน"]);
        setCheckedState(updatedCheckedState);
      } else {
        updatedCheckedState.forEach((item, index) => {
          if (item === true && !selected.includes(data[index])) {
            selected.push(data[index]);
            setSelected(selected);
          } else if (item === false && selected.includes(data[index])) {
            const newSelected = selected.filter((item) => data[index] !== item);
            setSelected(newSelected);
          }
        });
        updatedCheckedState.forEach((item, index) => {
          if (item === true && index !== 7) {
            updateSelectedDayList.push(dayList[index]);
          } else if (item === true && index === 7 && !allCheck) {
            updateSelectedDayList.filter((item) => dayList[index] !== item);
            updatedCheckedState[index] = false;
          } else {
            updateSelectedDayList.filter((item) => dayList[index] !== item);
          }
        });
        setSelectedDaylist(updateSelectedDayList);
        setCheckedState(updatedCheckedState);
      }
    } else if (position === 7 || allCheck) {
      if (updatedCheckedState[7] === true) {
        updatedCheckedState = checkedState.map(() => {
          return true;
        });
        setSelectedDaylist(["ทุกวัน"]);
        setCheckedState(updatedCheckedState);
      } else {
        updatedCheckedState = checkedState.map(() => {
          return false;
        });
        setSelectedDaylist([]);
        setCheckedState(updatedCheckedState);
      }
      updatedCheckedState.forEach((item, index) => {
        if (index !== 7 && item === true) {
          newSelectedDayList.push(data[index]);
        }
      });
      setSelected(newSelectedDayList);
    }
  };

  const showDropdownData = () => {
    if (selected !== null) {
      const posList: number[] = [];
      data.forEach((day, index) => {
        selected.forEach((select) => {
          if (select === day) {
            posList.push(index);
          }
        });
      });
      if (selected.length === 7) {
        posList.push(7);
      }
      const updatedCheckedState = checkedState.map((check, index) =>
        posList.includes(index) ? !check : check,
      );
      setCheckedState(updatedCheckedState);
    }
  };

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div className="w-80">
        <button
          className={`btn dropdown-toggle ${disabled ? "disabled" : ""} w-100 ${
            showDropdown === false ? "" : "show"
          }`}
          id="dropdown-basic-button"
          type="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={showDropdown}
          onClick={() => {
            if (isActive === true) {
              setIsActive(false);
              setShowDropdown(false);
            } else {
              setIsActive(true);
              setShowDropdown(true);
            }
            showDropdownData();
          }}
        >
          {selectedDayList.sort((a, b) => {
            return selectedDayList.indexOf(a) - selectedDayList.indexOf(b);
          })}
        </button>
        {isActive === true ? (
          <div className="d-flex justify-content-center">
            <ul className={`dropdown-menu w-60 shadow-lg ${showDropdown === false ? "" : "show"}`}>
              <div className="row">
                <div className="col-6">
                  {firstDayList.map((data, index) => (
                    <li key={index}>
                      <div className="d-flex align-items-center justify-content-start p-1">
                        <Checkbox
                          label={data.toString()}
                          value={checkedState[index]}
                          onChange={() => {
                            handleOnChange(index);
                          }}
                        />
                      </div>
                    </li>
                  ))}
                </div>
                <div className="col-6">
                  {lastDayList.map((data, index) => (
                    <li key={index}>
                      <div className="d-flex align-items-center justify-content-start p-1">
                        <Checkbox
                          label={data.toString()}
                          value={checkedState[index + 4]}
                          onChange={() => {
                            handleOnChange(index + 4);
                          }}
                        />
                      </div>
                    </li>
                  ))}
                </div>
              </div>
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export {
  DropdownList,
  RoomDropdownList,
  DiscountDropdownList,
  ActiveDiscountDropdownList,
  StaffDepartmentDropdownList,
  ActiveStaffDropdownList,
  DayDropdownList,
  AvaialbeDailyRoomDropdownList,
  UserDropdownList,
  ActiveMemberDropdownList,
  PaymentMethodDropdownList,
  RoomDailyDropdownList,
  MonthYearDropdownList,
  PurchasedProductDropdownList,
  CoWorkingRoomDropdownList,
  CoWorkingRoomNameDropdownList,
};
